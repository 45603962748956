import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Selector,
} from 'shared/components';
import { formatPolicies } from 'shared/helpers';

const ProductInformation = ({
  handleProductSelect,
  products,
  productPolicies,
  selectedProduct,
  selectedPolicy,
  handlePolicySelect,
}) => {
  const { t } = useTranslation();
  return (
    <section className="ProductInformation">
      <div className="section-header first">
        {t('Product information')}
      </div>
      <div className="section-row">
        <Label inputId="product-input" text={t('Product name')} />
        <Selector
          options={products}
          handleChange={handleProductSelect}
          value={get(selectedProduct, 'value')}
          inputId="product-input"
        />
      </div>
      <div className="section-row">
        <Label inputId="product-policy" text={t('License policy')} />
        <Selector
          options={formatPolicies(productPolicies)}
          handleChange={handlePolicySelect}
          value={selectedPolicy}
          inputId="product-policy"
        />
      </div>
    </section>
  );
};

ProductInformation.propTypes = {
  handleProductSelect: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  productPolicies: PropTypes.array.isRequired,
  selectedProduct: PropTypes.object.isRequired,
  selectedPolicy: PropTypes.string,
  handlePolicySelect: PropTypes.func.isRequired,
};

ProductInformation.defaultProps = {
  selectedPolicy: '',
};

export default ProductInformation;
