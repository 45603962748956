import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
  Page,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';
import EditProfileForm from '../EditProfileForm';
import ChangePasswordForm from '../ChangePasswordForm';

const ProfileDetails = ({
  refreshUser,
  user,
}) => {
  const { t } = useTranslation();
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false);
  const [isChangePasswordDisplayed, setChangePasswordDisplay] = useState(false);

  return (
    <div className="ProfileDetails">
      <Page
        title={t('My profile')}
        breadcrumb={<NavLink to="/">{t('Home')}</NavLink>}
      >
        <div className="ProfileDetails-inner">
          <div className="list-header">
            <div>
              <Button
                theme="success"
                onClick={() => setEditFormDisplay(true)}
              >
                {t('Edit profile')}
              </Button>
            </div>
            <div>
              <Button
                theme="info"
                onClick={() => setChangePasswordDisplay(true)}
              >
                {t('Change password')}
              </Button>
            </div>
          </div>
          <DescriptionTable
            details={[
              { label: t('Email'), value: displayValue(get(user, 'true_email')) },
              { label: t('First Name'), value: displayValue(get(user, 'first_name')) },
              { label: t('Last Name'), value: displayValue(get(user, 'last_name')) },
              {
                label: t('Subscribed to email'),
                value: get(user, 'subscribed_to_email') ?
                  <IconCheckmark color="#10ac84" height="14px" /> :
                  <IconDisabled color="#aaa" height="14px" />,
              },
              { label: t('Phone number'), value: displayValue(get(user, 'phone_number')) },
            ]}
          />
        </div>
      </Page>
      {
        isEditFormDisplayed && (
          <EditProfileForm
            user={user}
            refreshUser={refreshUser}
            close={() => setEditFormDisplay(false)}
          />
        )
      }
      {
        isChangePasswordDisplayed && (
          <ChangePasswordForm
            user={user}
            close={() => setChangePasswordDisplay(false)}
          />
        )
      }
    </div>
  );
};

ProfileDetails.propTypes = {
  refreshUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default ProfileDetails;
