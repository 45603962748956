import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconInfo,
  Tooltip,
} from 'shared/components';

const TooltipHeader = (label, content) => {
  const { t } = useTranslation();
  return (
    <div className="tooltip-header">
      {label}
      <Tooltip
        content={content || t('This license will no longer work on this date at 00:00 UTC')}
        active
      >
        <span><IconInfo height="12px" width="12px" /></span>
      </Tooltip>
    </div>
  );
};


export default TooltipHeader;
