import React from 'react';
import get from 'lodash.get';
import { Redirect } from 'react-router-dom';

const isAuthorized = (WrappedComponent, user = {}) => {
  const wrapped = () => {
    if (!get(user, 'id')) {
      return <Redirect to="/login" />;
    }

    return <WrappedComponent />;
  };

  return wrapped;
};

export default isAuthorized;
