/* eslint-disable camelcase */
import React from 'react';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { parseObject, isUserManager, hasPermission } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import {
  IconHome,
  IconUser,
  IconLicense,
  IconOrder,
} from 'shared/components';
import { NavLink } from '../components';
import './styles.scss';

const Sidebar = () => {
  const { t } = useTranslation();
  const permissionsList = useSelector(state => get(state, 'user.details.permissions'));
  const userCompany = useSelector(state => get(state, 'user.details.company'));
  const user = useSelector(state => get(state, 'user.details'));
  const isManager = isUserManager(user);
  const { portal_data } = userCompany;
  const portalData = parseObject(portal_data, initialPortalData);
  const sidebarBackground = get(portalData, 'sidebarBackground') || '#fff';

  const canCreateOrder = hasPermission(permissionsList, 'create_order');

  return (
    <aside className="Sidebar" style={{ backgroundColor: sidebarBackground }}>
      <div className="Sidebar-inner">
        <NavLink
          activeClassName="NavLink-active"
          className="NavLink"
          exact
          to="/"
        >
          <header>
            <div className="NavLink-icon">
              <IconHome height="20px" width="20px" color="#777" />
            </div>
            {t('Dashboard')}
          </header>
        </NavLink>
        {isManager && canCreateOrder && (
          <NavLink
            activeClassName="NavLink-active"
            className="NavLink"
            to="/create-order"
          >
            <header>
              <div className="NavLink-icon">
                <IconOrder height="16px" width="16px" color="#777" />
              </div>
              {t('Create order')}
            </header>
          </NavLink>
        )}
        {isManager && (
          <NavLink
            activeClassName="NavLink-active"
            className="NavLink"
            to="/orders"
          >
            <header>
              <div className="NavLink-icon">
                <IconOrder height="16px" width="16px" color="#777" />
              </div>
              {t('Orders')}
            </header>
          </NavLink>
        )}
        <NavLink
          activeClassName="NavLink-active"
          className="NavLink"
          to="/licenses"
        >
          <header>
            <div className="NavLink-icon">
              <IconLicense height="16px" width="16px" color="#777" />
            </div>
            {t('Licenses')}
          </header>
        </NavLink>
        <NavLink
          activeClassName="NavLink-active"
          className="NavLink"
          to="/profile"
        >
          <header>
            <div className="NavLink-icon">
              <IconUser height="18px" width="20px" color="#777" />
            </div>
            {t('My profile')}
          </header>
        </NavLink>
      </div>
    </aside>
  );
};

export default Sidebar;
