import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  IconDisabled,
} from 'shared/components';
import './styles.scss';

const Modal = ({
  children,
  size,
  closeCb,
  confirmCb,
  title,
  disabled,
  hideFooter,
  ...rest
}) => {
  const { t } = useTranslation();
  return createPortal(
    <div
      className="Modal"
      {...rest}
    >
      <div className={`modal-inner modal-inner-size-${size}`}>
        {title && <div className="modal-header">{title}</div>}
        <div className="modal-body">
          {children}
        </div>
        <div className={hideFooter ? 'modal-footer hidden' : 'modal-footer'}>
          <Button
            theme="success"
            onClick={confirmCb}
            disabled={disabled}
          >
            {t('Confirm')}
          </Button>
          <Button
            onClick={closeCb}
          >
            {t('Cancel')}
          </Button>
        </div>
        <button
          type="button"
          className="modal-dismiss"
          onClick={closeCb}
        >
          <IconDisabled
            height="16px"
          />
        </button>
      </div>
    </div>,
    document.body,
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired,
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideFooter: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

Modal.defaultProps = {
  disabled: false,
  hideFooter: false,
  size: 'md',
  title: '',
};

export default Modal;
