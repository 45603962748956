import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import isString from 'lodash.isstring';
import Table from 'react-table';
import 'react-table/react-table.css';
import './styles.scss';

const List = ({
  clickable,
  columns,
  data,
  handleClick,
  hasDropdown,
  resizable,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <div className={`List ${clickable ? 'List-clickable' : ''} ${hasDropdown ? 'List-hasDropdown' : ''}`}>
      <Table
        columns={columns}
        data={data}
        defaultPageSize={20}
        getTrProps={(state, rowInfo) => ({
          onClick: () => handleClick(rowInfo),
        })
        }
        getTdProps={() => ({
          style: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          },
          onClick: (e, handleOriginal) => {
            const targetClassName = e.target.className;
            let isExpanderClicked;
            if (isString(targetClassName)) {
              isExpanderClicked = targetClassName.includes('rt-expander') || targetClassName.includes('action-menu');
            } else {
              isExpanderClicked = false;
            }
            if (handleOriginal) {
              if (isExpanderClicked) {
                handleOriginal();
                e.preventDefault();
                e.stopPropagation();
              }
            }
          },
        })
        }
        resizable={resizable}
        showPageSizeOptions={false}
        previousText={t('Previous')}
        nextText={t('Next')}
        loadingText={t('Loading')}
        noDataText={t('Search returned 0 results')}
        pageText={t('Page')}
        ofText={t('of')}
        rowsText={t('rows')}
        {...rest}
      />
    </div>
  );
};

List.propTypes = {
  clickable: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  handleClick: PropTypes.func,
  hasDropdown: PropTypes.bool,
  resizable: PropTypes.bool,
};

List.defaultProps = {
  clickable: false,
  hasDropdown: false,
  handleClick: () => {},
  resizable: false,
};

export default List;
