import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  IconSearch,
  Selector,
  TextInput,
} from 'shared/components';
import './styles.scss';

const SearchForm = ({
  options,
  selectedValue,
  handleSearchTypeChange,
  handleSearchSubmit,
  activeQuery,
  handleClear,
  disabled,
}) => {
  const [query, setQuery] = useState('');

  const handleQueryClear = () => {
    setQuery('');
    handleClear();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchSubmit(query);
  };

  return (
    <div className="SearchForm">
      <div className="search-selector">
        <Selector
          handleChange={handleSearchTypeChange}
          options={options}
          value={selectedValue}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <TextInput
          handleChange={val => setQuery(val)}
          value={query}
        />
        {
          activeQuery &&
          (
            <button type="button" onClick={handleQueryClear} disabled={disabled}>
              &times;
            </button>
          )
        }
        <Button
          type="submit"
          theme="info"
          disabled={disabled}
        >
          <IconSearch fill="#fff" />
        </Button>
      </form>
    </div>
  );
};

SearchForm.propTypes = {
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  handleSearchTypeChange: PropTypes.func.isRequired,
  handleSearchSubmit: PropTypes.func.isRequired,
  activeQuery: PropTypes.string.isRequired,
  handleClear: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

SearchForm.defaultProps = {
  disabled: false,
};

export default SearchForm;
