import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { IconInfo, Tooltip } from 'shared/components';
import { productFeatureTypes, defaultDateFormat } from 'shared/constants';
import { capitalizeFirstLetter, formatDate } from 'shared/helpers';
import './styles.scss';

const SelectedFeaturesTags = ({ features, product }) => {
  const { t } = useTranslation();
  if (!Array.isArray(features) || !features.length) {
    return '-';
  }

  return (
    <div className="SelectedFeaturesTags">
      {features.map((f) => {
        const featureID = get(f, 'id') || get(f, 'value');
        let name = get(f, 'name') || get(f, 'label') || get(f, 'product_feature.name');
        if (!name) {
          const productFeatures = get(product, 'product_features') || [];
          const feat = productFeatures.find(fe => fe.id === get(f, 'product_feature'));
          name = get(feat, 'name') || '';
        }
        const type = get(f, 'feature_type') || get(f, 'product_feature.feature_type');
        const isConsumption = type === productFeatureTypes.consumption;
        const allowUnlimitedConsumptions = get(f, 'allow_unlimited_consumptions');
        const allowNegativeConsumptions = get(f, 'allow_negative_consumptions');
        const maxConsumption = get(f, 'max_consumption');
        const allowOverage = get(f, 'allow_overages');
        const resetConsumption = get(f, 'reset_consumption');
        const expiryDate = get(f, 'expiry_date');
        const isFloating = get(f, 'is_floating_cloud') || get(f, 'is_floating');
        const isFloatingCloud = get(f, 'is_floating_cloud');
        return (
          <div key={featureID} className="SFTag">
            <Tooltip
              overlayClassName="sf-tags-tooltip"
              active
              content={(
                <div className="sf-tags-tooltip-inner">
                  <div className="type">
                    <span className="value">
                      {`${capitalizeFirstLetter(t(type))} ${t('type')}`}
                    </span>
                  </div>
                  {isConsumption && (
                    <div className="max_consumptions">
                      <span className="value">
                        {allowUnlimitedConsumptions ? t('Unlimited consumptions') : `${maxConsumption} ${t('max consumptions')}`}
                      </span>
                    </div>
                  )}
                  {isConsumption && allowNegativeConsumptions && (
                  <div className="negative_consumptions">
                    <span className="value">
                      {t('Allow negative consumptions')}
                    </span>
                  </div>
                  )}
                  {allowOverage && (
                    <div className="allowOverage">
                      <span className="value">
                        {`${get(f, 'max_overages')} ${t('max overages')}`}
                      </span>
                    </div>
                  )}
                  {resetConsumption && (
                    <div className="allowOverage">
                      <span className="value">
                        {`${capitalizeFirstLetter(t(get(f, 'consumption_period')))} ${t('consumptions reset')}`}
                      </span>
                    </div>
                  )}
                  {isFloating && (
                  <>
                    <div className="type">
                      <span className="value">
                        {isFloatingCloud ? t('Is floating cloud') : t('Offline floating feature')}
                      </span>
                    </div>
                    <div className="floating-users">
                      <span className="value">
                        {`${t('Max simultaneous license users')}: ${get(f, 'floating_users')}`}
                      </span>
                    </div>
                    <div className="floating-timeout">
                      <span className="value">
                        {`${t('Floating timeout')}: ${get(f, 'floating_timeout')}`}
                      </span>
                    </div>
                  </>
                  )}
                  {expiryDate && (
                    <div className="expiry_date">
                      <span className="value">
                        {`${t('Expiry date')}: ${formatDate(expiryDate, defaultDateFormat)}`}
                      </span>
                    </div>
                  )}
                </div>
              )}
            >
              <div className="SFTag-inner">
                <span>{name}</span>
                <IconInfo height="10px" width="10px" />
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

SelectedFeaturesTags.propTypes = {
  features: PropTypes.array,
  product: PropTypes.object.isRequired,
};

SelectedFeaturesTags.defaultProps = {
  features: [],
};

export default SelectedFeaturesTags;
