import React, { useEffect, useState, useCallback } from 'react';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ContentLoader,
  Label,
  Notification,
  PasswordInput,
} from 'shared/components';
import {
  validatePassword,
  debouncedValidatePassword,
  validatePasswordAgain,
  debouncedValidatePasswordAgain,
} from 'shared/validation';
import { getErrMsg, getParameterByName, parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import { loginSuccess } from 'redux/user/actions';
import { confirmPasswordReset, checkPasswordResetKey } from '../actions';
import Logo from './ls-logo.svg';
import './styles.scss';

const SetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const key = getParameterByName('key', window.location.href);

  const isCheckingUser = useSelector(state => get(state, 'user.checkingUser'));
  const user = useSelector(state => get(state, 'user.details'));
  const customDomain = useSelector(state => get(state, 'user.customDomain'));

  const whitelabeledCompanyName = get(customDomain, 'name') || '';
  const whitelabeledCompanyLogo = get(customDomain, 'logo');

  const [isLoading, setLoading] = useState(false);
  const [keyLoading, setKeyLoading] = useState(true);
  const [keyError, setKeyError] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passwordAgainError, setPasswordAgainError] = useState('');

  const getLogo = () => {
    if (!customDomain) {
      return (
        <img src={Logo} alt="logo" height="90px" />
      );
    }
    if (whitelabeledCompanyLogo) {
      return (
        <img src={whitelabeledCompanyLogo} alt="logo" height="90px" />
      );
    }
    return null;
  };

  const checkKey = useCallback(() => {
    checkPasswordResetKey(key)
      .then(() => {
        setKeyLoading(false);
        setKeyError(false);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        // const errMsg = getErrMsg(err, 'error');
        // Notification('error', t('Error occured'), errMsg);
        Notification('error', t('Error occured'));
        setKeyLoading(false);
        setKeyError(true);
      });
  }, [key]);

  useEffect(() => {
    if (get(user, 'id')) {
      history.push('/');
    }
  }, [user, history]);

  useEffect(() => {
    if (!key) {
      history.push('/');
    } else {
      checkKey();
    }
  }, [key, checkKey]);

  const validateNewPass = async () => {
    let errors;
    try {
      errors = await validatePassword(password);
      setPasswordError(errors);
    } catch (err) {
      // console.log(err);
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const validateAgainPass = async () => {
    let errors;
    try {
      errors = await validatePasswordAgain(password, passwordAgain);
      setPasswordAgainError(errors);
    } catch (err) {
      // console.log(err);
    }
    if (errors) {
      return false;
    }
    return true;
  };

  const isFormValid = async () => {
    const isNewPassValid = await validateNewPass();
    const isAgainPassValid = await validateAgainPass();
    return isNewPassValid && isAgainPassValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    const passwordData = {
      key,
      password,
    };
    setLoading(true);

    confirmPasswordReset(passwordData)
      .then((res) => {
        dispatch(loginSuccess(get(res, 'data.user')));
        history.push('/');
        setLoading(false);
        Notification('success', t('Password changed'));
      })
      .catch((err) => {
        const errMsg = getErrMsg(err, 'error');
        Notification('error', t('Error occured'), errMsg);
        setLoading(false);
        Notification('error', t('Password not changed'), t('There was an error while saving your changes'));
      });
    return true;
  };

  const portalData = parseObject(get(customDomain, 'portal_data'), initialPortalData);
  const customTitle = get(portalData, 'loginTitle') || `${whitelabeledCompanyName} ${t('User Portal')}`;

  if (keyLoading) {
    return (
      <div className="SetPassword">
        <div className="form-container">
          <div className="login-form">
            <fieldset>
              <div className="row logo-cont">
                {getLogo()}
                {whitelabeledCompanyName && (
                  <div className="company-name">
                    {customTitle}
                  </div>
                )}
              </div>
              <div>
                <ContentLoader />
              </div>
              <div className="row forgot-pass submitted">
                <a className="login-forgot" href="/login">
                  {t('Back to login')}
                </a>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }

  if (keyError) {
    return (
      <div className="SetPassword">
        <div className="form-container">
          <div className="login-form">
            <fieldset>
              <div className="row logo-cont">
                {getLogo()}
                {whitelabeledCompanyName && (
                  <div className="company-name">
                    {customTitle}
                  </div>
                )}
              </div>
              <div className="row error">
                {t('Your request is not valid or it has expired.')}
              </div>
              <div className="row btn-cont">
                <Button
                  size="lg"
                  theme="success"
                  onClick={() => history.push('/reset-password')}
                >
                  {t('Resend')}
                </Button>
              </div>
              <div className="row forgot-pass submitted">
                <a className="login-forgot" href="/login">
                  {t('Back to login')}
                </a>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="SetPassword">
      <div className="form-container">
        <form
          className="login-form"
          id="login-form"
          onSubmit={handleSubmit}
        >
          <fieldset
            disabled={isCheckingUser}
          >
            <div className="row logo-cont">
              {getLogo()}
              {whitelabeledCompanyName && (
                <div className="company-name">
                  {customTitle}
                </div>
              )}
            </div>
            <div className="row title">
              {t('Set password')}
            </div>
            <div className="row input-cont">
              <Label inputId="password" text={t('New password')} />
              <PasswordInput
                disabled={isLoading}
                id="password"
                value={password}
                error={passwordError}
                handleChange={(val) => {
                  setPassword(val);
                  debouncedValidatePassword(val).then(err => setPasswordError(err));
                }}
              />
            </div>
            <div className="row input-cont">
              <Label inputId="password-again" text={t('Password again')} />
              <PasswordInput
                disabled={isLoading}
                id="password-again"
                value={passwordAgain}
                error={passwordAgainError}
                handleChange={(val) => {
                  setPasswordAgain(val);
                  debouncedValidatePasswordAgain(password, val).then(err => setPasswordAgainError(err));
                }}
              />
            </div>
            <div className="row btn-cont">
              <Button
                disabled={isCheckingUser || isLoading}
                size="lg"
                theme="success"
                type="submit"
              >
                {t('Submit')}
              </Button>
            </div>
            <div className="row forgot-pass">
              <a className="login-forgot" href="/login">
                {t('Back to login')}
              </a>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
