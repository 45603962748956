import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { errorMsg, validationTimeLong } from 'shared/constants';

export const validateCurrentPassword = val => new Promise((resolve) => {
  let error = '';
  if (!val.length) {
    error = errorMsg.passCurrent;
    resolve(error);
  }

  resolve(error);
});

export const validatePassword = val => new Promise((resolve) => {
  let error = '';

  if (!val) {
    error = errorMsg.required;
    resolve(error);
  }

  if (val.length < 8) {
    error = errorMsg.passCharNum;
    resolve(error);
  }

  const lowercaseRegex = /[a-z]/;
  if (!lowercaseRegex.test(val)) {
    error = errorMsg.passLowercase;
    resolve(error);
  }

  const uppercaseRegex = /[A-Z]/;
  if (!uppercaseRegex.test(val)) {
    error = errorMsg.passUppercase;
    resolve(error);
  }

  const numberRegex = /[0-9]/;
  if (!numberRegex.test(val)) {
    error = errorMsg.passNumber;
    resolve(error);
  }

  resolve(error);
});

export const validatePasswordAgain = (pass1, pass2) => new Promise((resolve) => {
  let error = '';
  if (pass1 !== pass2) {
    error = errorMsg.passMatch;
  }

  if (!pass1) {
    error = errorMsg.required;
  }

  resolve(error);
});

export const debouncedValidateCurrentPassword = AwesomeDebouncePromise(
  validateCurrentPassword, validationTimeLong,
);

export const debouncedValidatePassword = AwesomeDebouncePromise(
  validatePassword, validationTimeLong,
);

export const debouncedValidatePasswordAgain = AwesomeDebouncePromise(
  validatePasswordAgain, validationTimeLong,
);
