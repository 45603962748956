import api from 'shared/api';

export const resetDeviceAction = (id) => {
  const url = `/api/v1/devices/${id}/reset/`;
  return api.post(url);
};

export const revokeDeviceAction = (id) => {
  const url = `/api/v1/devices/${id}/`;
  return api.patch(url, { floating_in_use: false });
};

export const borrowDevice = (id, borrowedDateTime) => {
  const url = `/api/v1/devices/${id}/borrow/`;
  return api.post(url, { borrowed_until: borrowedDateTime });
};

export const releaseBorrowedDevice = (deviceID) => {
  const url = `/api/v1/devices/${deviceID}/borrow_release/`;
  return api.post(url);
};
