import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'x-csrftoken';
axios.interceptors.response.use((response) => {
  if (response.status === 401
    || response.status === 403) {
    window.location.href = '/login'; // eslint-disable-line no-undef
  }
  return response;
}, error => Promise.reject(error));

const endpoint = '';

const getPath = url => `${endpoint}${url}`;

const api = {
  // eslint-disable-next-line no-unused-vars
  get: (url, data = undefined, config = undefined) => axios.get(getPath(url), config),
  post: (url, data = undefined, config = undefined) => axios.post(getPath(url), data, config),
  patch: (url, data, config) => axios.patch(getPath(url), data, config),
  // eslint-disable-next-line no-unused-vars
  delete: (url, data = undefined, config = undefined) => axios.delete(getPath(url), config),
};

export default api;
