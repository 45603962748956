import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const StatusCard = ({
  status,
  text,
}) => (
  <span className="StatusCard">
    <span className={`StatusCard-${status}`} />
    {text}
  </span>
);

StatusCard.propTypes = {
  status: PropTypes.oneOf(['success', 'warning', 'error']).isRequired,
  text: PropTypes.string.isRequired,
};

export default StatusCard;
