import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <g>
      <path d="M12,14H38V43a3,3,0,0,1-3,3H15a3,3,0,0,1-3-3ZM31,6V4H19V6H7v6H43V6Z" />
    </g>
  </Base>
);
