import api from 'shared/api';

export const setLicenseUserActivations = (licenseID, data) => {
  const url = `/api/v1/licenses/${licenseID}/set_users_activations/`;
  return api.post(url, data);
};
  // {
  //   "{license_user_id_1}": {
  //     "max_activations": 100
  //   },
  //   "{license_user_id_2}": {
  //     "reset_total_activations": true
  //   },
  //   "{license_user_id_3}": {
  //     "max_activations": 200,
  //       "reset_total_activations": true
  //   }
  // }
