import api from 'shared/api';

// export const removeLicenseUser = (licenseId) => {
//   const data = { license_user: null };
//   const url = `/api/v1/licenses/${licenseId}/`;
//   return api.patch(url, data);
// };

export const removeLicenseUser = (licenseID, userID) => {
  const url = `/api/v1/licenses/${licenseID}/unassign_user/`;
  return api.post(url, { license_user_id: userID });
};
