import * as actionTypes from './actionTypes';

const initialState = {
  loading: true,
  list: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.getOrdersInit:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getOrdersSuccess:
      return {
        ...state,
        loading: false,
        list: action.orders,
      };
    case actionTypes.getOrdersError:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
