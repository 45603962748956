import React, { useState, useEffect, useCallback } from 'react';
import get from 'lodash.get';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isUserManager } from 'shared/helpers';
import {
  ContentLoader,
  NotFound,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
} from 'shared/components';
import OrderDetails from './components/OrderDetails';
import LicensesList from './components/LicensesList';
import OrderManagers from './components/OrderManagers';
import CustomerDetails from './components/CustomerDetails';
import { getOrder } from './actions';

const OrderContainer = () => {
  const { t } = useTranslation();
  const { orderId } = useParams();
  const user = useSelector(state => get(state, 'user.details'));
  const isManager = isUserManager(user);

  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [order, setOrder] = useState(null);

  const fetchOrder = useCallback(() => {
    getOrder(orderId)
      .then((res) => {
        setOrder(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setNotFound(true);
      });
  }, [orderId]);

  useEffect(() => {
    fetchOrder();
  }, [fetchOrder]);

  if (!isManager || notFound) {
    return <NotFound />;
  }

  if (isLoading) {
    return <Page><ContentLoader text={t('Getting order details')} /></Page>;
  }

  return (
    <Page
      title={`${t('Order')}: ${get(order, 'store_id')}`}
      breadcrumb={<NavLink to="/orders">{t('Orders')}</NavLink>}
    >
      <div className="OrderContainer-tabs">
        <Tabs>
          <TabsHeader>
            <Tab>{t('Order details')}</Tab>
            <Tab>{t('Licenses')}</Tab>
            <Tab>{t('License managers')}</Tab>
            <Tab>{t('Customer')}</Tab>
          </TabsHeader>
          <TabContent>
            <OrderDetails order={order} />
          </TabContent>
          <TabContent>
            <LicensesList order={order} refetchOrder={fetchOrder} />
          </TabContent>
          <TabContent>
            <OrderManagers order={order} />
          </TabContent>
          <TabContent>
            <CustomerDetails order={order} />
          </TabContent>
        </Tabs>
      </div>
    </Page>
  );
};

export default OrderContainer;
