import { errorMsg } from 'shared/constants';

export const validatePassword = (val) => {
  let error = '';

  if (!val) {
    error = errorMsg.required;
    return (error);
  }

  if (val.length < 8) {
    error = errorMsg.passCharNum;
    return (error);
  }

  return (error);
};
