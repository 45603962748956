import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
} from 'shared/components';
import icon from '../sunk-ship.svg';
import './styles.scss';

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="NotFound-container">
      <img src={icon} alt="Not found" height="100px" />
      <h2>
        {t('Page not found')}
      </h2>
      <p>
        {t('We can\'t seem to find the page you\'re looking for.')}
      </p>
      <Button
        onClick={() => history.push('/')}
        theme="info"
      >
        {t('Go to homepage')}
      </Button>
    </div>
  );
};

export default NotFound;
