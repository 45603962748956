import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DescriptionTable,
} from 'shared/components';
import {
  displayValue,
} from 'shared/helpers';

const CustomerDetails = ({
  order,
}) => {
  const { t } = useTranslation();
  const { customer } = order;

  return (
    <div className="CustomerDetails CustomerContainer">
      <DescriptionTable
        details={[
          { label: t('Email'), value: displayValue(get(customer, 'email')) },
          { label: t('First Name'), value: displayValue(get(customer, 'first_name')) },
          { label: t('Last Name'), value: displayValue(get(customer, 'last_name')) },
          { label: t('Address'), value: displayValue(get(customer, 'address')) },
          { label: t('City'), value: displayValue(get(customer, 'city')) },
          { label: t('State / Province'), value: displayValue(get(customer, 'state')) },
          { label: t('Country'), value: displayValue(get(customer, 'country')) },
          { label: t('Zipcode / Postcode'), value: displayValue(get(customer, 'postcode')) },
          { label: t('Phone Number'), value: displayValue(get(customer, 'phone')) },
          { label: t('Company'), value: displayValue(get(customer, 'company_name')) },
          { label: t('Reference'), value: displayValue(get(customer, 'reference')) },
        ]}
      />
    </div>
  );
};

CustomerDetails.propTypes = {
  order: PropTypes.object.isRequired,
};

export default CustomerDetails;
