import get from 'lodash.get';

export const mapLicenses = (data) => {
  const licenses = [];

  if (Array.isArray(data)) {
    data.forEach((d) => {
      const licensesList = get(d, 'licenses') || [];

      licensesList.forEach((l) => {
        const license = {
          ...l,
          product: get(l, 'product.product_name'),
        };
        licenses.push(license);
      });
    });
  }

  return licenses;
};
