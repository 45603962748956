import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import appStore from './rootReducer';

const composeEnhancers =
  process.env.NODE_ENV !== 'production'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

const usedMiddleware = [thunk];

const store = createStore(
  appStore,
  composeEnhancers(applyMiddleware(...usedMiddleware)),
);

export default store;
