import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  List,
  Notification,
} from 'shared/components';
import { fetchCustomFields } from './actions';
import { getLicenseCustomFields } from './helpers';

const CustomFields = ({
  product,
  license,
}) => {
  const { t } = useTranslation();
  const licenseID = get(license, 'id');

  const [customFields, setCustomFields] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const getCustomFields = useCallback(async () => {
    let data;
    try {
      data = await fetchCustomFields(licenseID);
      const fields = getLicenseCustomFields(product, get(data, 'data.results'));
      setCustomFields(fields);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      Notification('error', t('There was an error while getting your data'));
    }
  }, [licenseID, product]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  return (
    <div className="CustomFields">
      <List
        columns={[
          { accessor: 'name', Header: t('Name') },
          {
            id: 'value',
            // field.value when there is license custom field
            // field.default_value when there is product custom field
            accessor: cellData => cellData.value || cellData.default_value,
            Header: t('Value'),
          },
        ]}
        data={customFields}
        minRows={2}
        pageSize={20}
        loading={isLoading}
      />
    </div>
  );
};

CustomFields.propTypes = {
  product: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
};

export default CustomFields;
