import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DescriptionTable,
  Label,
  TextInput,
} from 'shared/components';
import { displayValue } from 'shared/helpers';
import './styles.scss';

const ExistingOrderDetails = ({
  formState,
  handleForward,
}) => {
  const { t } = useTranslation();
  return (
    <div className="ExistingOrderDetails">
      <div className="ExistingOrderDetails-data">
        <div className="order-data">
          <div>
            <Label text={t('Order ID')} inputId="customer-order-id" />
            <TextInput
              id="customer-order-id"
              handleChange={() => { }}
              value={get(formState, 'id')}
              disabled
            />
          </div>
          {get(formState, 'orderReference') && (
            <div>
              <Label text={t('Order reference')} inputId="order-reference" />
              <TextInput
                id="order-reference"
                handleChange={() => { }}
                value={get(formState, 'orderReference')}
                disabled
              />
            </div>
          )}
        </div>
        <div><Label text={t('Customer details')} inputId="customer-details" /></div>
        <DescriptionTable
          details={[
            { label: t('Email'), value: displayValue(get(formState, 'customerEmail')) },
            { label: t('First Name'), value: displayValue(get(formState, 'customerFirstName')) },
            { label: t('Last Name'), value: displayValue(get(formState, 'customerLastName')) },
            { label: t('Organization'), value: displayValue(get(formState, 'customerOrganization')) },
            { label: t('Address'), value: displayValue(get(formState, 'customerAddress')) },
            { label: t('City'), value: displayValue(get(formState, 'customerCity')) },
            { label: t('State / Province'), value: displayValue(get(formState, 'customerState')) },
            { label: t('Country'), value: displayValue(get(formState, 'customerCountry')) },
            { label: t('Zipcode / Postcode'), value: displayValue(get(formState, 'customerZipcode')) },
            { label: t('Phone number'), value: displayValue(get(formState, 'customerPhoneNumber')) },
            { label: t('Customer reference'), value: displayValue(get(formState, 'customerReference')) },
          ]}
        />
      </div>
      <div className="ExistingOrderDetails-actions">
        <Button theme="info" size="lg" onClick={handleForward}>
          {t('Next')}
        </Button>
      </div>
    </div>
  );
};

ExistingOrderDetails.propTypes = {
  formState: PropTypes.object.isRequired,
  handleForward: PropTypes.func.isRequired,
};

export default ExistingOrderDetails;
