import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Dropdown,
  DatePicker,
  IconCalendar,
  InputErrorMessage,
} from 'shared/components';
import './styles.scss';

const DateInput = ({
  handleChange,
  handleDateSelect,
  disabled,
  error,
  ...rest
}) => {
  const { t } = useTranslation();
  const pickerRef = useRef();
  const [displayDatePicker, setDatePickerDisplay] = useState(false);

  return (
    <div className="DateInput">
      <button
        className="DateInput-button"
        onClick={() => {
          if (!disabled) {
            setDatePickerDisplay(!displayDatePicker);
          }
        }}
        type="button"
      >
        <IconCalendar />
      </button>
      <input
        className={error ? 'has-error' : ''}
        type="text"
        disabled={disabled}
        onChange={e => handleChange(e.target.value)}
        onFocus={() => {
          if (displayDatePicker) { setDatePickerDisplay(false); }
        }}
        placeholder="YYYY-MM-DD"
        {...rest}
      />
      {displayDatePicker && (
        <Dropdown ref={pickerRef} close={() => setDatePickerDisplay(!displayDatePicker)}>
          <div className="DateInput-popup">
            <DatePicker
              selectDate={(val) => {
                handleChange(val);
                handleDateSelect(val);
                setDatePickerDisplay(false);
              }}
            />
          </div>
        </Dropdown>
      )}
      <InputErrorMessage text={t(error)} />
    </div>
  );
};

DateInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDateSelect: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
};

DateInput.defaultProps = {
  error: '',
  disabled: false,
  handleDateSelect: () => {},
};

export default DateInput;
