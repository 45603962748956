import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import sortBy from 'lodash.sortby';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  DescriptionTable,
  IconDelete,
  IconEdit,
  List,
  SelectedCustomFieldsTags,
  SelectedFeaturesTags,
} from 'shared/components';
import {
  displayValue,
  capitalizeFirstLetter,
  displayMaxOveragesValue,
} from 'shared/helpers';
import {
  licenseTypes,
} from 'shared/constants';

const SelectedBundlesTable = ({
  deleteBundleFromList,
  handleBundleEdit,
  selectedBundles,
  showActionBtns,
}) => {
  const { t } = useTranslation();
  const products = useSelector(state => get(state, 'user.details.products') || []);
  const [tableExpanded, setTableExpanded] = useState({});

  return (
    <div className="SelectedBundlesTable">
      <List
        data={selectedBundles || []}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={(row) => {
          const policies = get(row, 'original.selected_bundle_policies') || [];
          const sortedPolicies = sortBy(policies, 'product.id');
          return (
            <div className="SubComponent">
              {sortedPolicies.map((p) => {
                const product = products.find(prod => prod.id === get(p, 'product.id'));
                const productName = get(product, 'product_name');
                const productAuthMethod = get(product, 'authorization_method') || '';
                const isUserBasedProduct = productAuthMethod === 'user';
                const isTimeLimited = get(p, 'default_license_type') === licenseTypes.time_limited;
                const isSubscription = get(p, 'default_license_type') === licenseTypes.subscription;
                const isConsumption = get(p, 'default_license_type') === licenseTypes.consumption;
                const isTrial = get(p, 'allow_trial');
                const trialDays = get(p, 'trial_days');
                const trialValidity = `${trialDays} ${'days'}`;
                const policyFeatures = get(p, 'license_product_feature_templates') || [];
                const policyCFs = get(p, 'license_custom_field_templates') || [];

                const shouldShowFeaturesTab = policyFeatures.length > 0;
                const shouldShowCustomFieldsTab = policyCFs.length > 0;

                return (
                  <div className="policy-row">
                    <DescriptionTable
                      details={[
                        {
                          label: t('Product name'),
                          value: displayValue(productName),
                        },
                        {
                          label: t('License Type'),
                          value: displayValue(t(get(p, 'default_license_type'))),
                        },
                        {
                          label: t('Max activations'),
                          value: displayValue(get(p, 'max_activations')),
                        },
                        {
                          label: isUserBasedProduct ? t('Max license users') : null,
                          value: (get(p, 'unlimited_max_license_users') || get(p, 'max_license_users') === 0) ? t('Unlimited') : displayValue(get(p, 'max_license_users')),
                        },
                        {
                          label: (isTimeLimited || isSubscription) ? t('Valid duration') : null,
                          value: displayValue(get(p, 'valid_duration')),
                        },
                        {
                          label: t('Is trial'),
                          value: isTrial ? t('Yes') : t('No'),
                        },
                        {
                          label: isTrial ? t('Trial days') : null,
                          value: trialValidity,
                        },
                        // show consumption values
                        {
                          label: isConsumption ? t('Max consumptions') : null,
                          value: displayValue(get(p, 'max_consumptions')),
                        },
                        {
                          label: isConsumption ? t('Allow overages') : null,
                          value: get(p, 'allow_overages') ? t('Yes') : t('No'),
                        },
                        {
                          label: isConsumption ? t('Max overages') : null,
                          value: displayMaxOveragesValue(p),
                        },
                        {
                          label: isConsumption ? t('Reset consumption') : null,
                          value: get(p, 'reset_consumption') ? t('Yes') : t('No'),
                        },
                        {
                          label: isConsumption ? t('Consumption period') : null,
                          value: displayValue(capitalizeFirstLetter(t(get(p, 'consumption_period')))),
                        },
                        // show everything else
                        {
                          label: get(p, 'enable_maintenance_period') ? t('Maintenance duration') : null,
                          value: displayValue(get(p, 'maintenance_duration')),
                        },
                        {
                          label: get(p, 'is_floating') ? t('Offline floating license') : null,
                          value: get(p, 'is_floating') ? t('Yes') : t('No'),
                        },
                        {
                          label: get(p, 'is_floating_cloud') ? t('Is floating cloud') : null,
                          value: get(p, 'is_floating_cloud') ? t('Yes') : t('No'),
                        },
                        {
                          label: (get(p, 'is_floating') || get(p, 'is_floating_cloud')) ? t('Max simultaneous license users') : null,
                          value: displayValue(get(p, 'floating_users')),
                        },
                        {
                          label: (get(p, 'is_floating') || get(p, 'is_floating_cloud')) ? t('Floating timeout') : null,
                          value: `${displayValue(get(p, 'floating_timeout'))} min`,
                        },
                        {
                          label: get(p, 'can_borrow') ? t('Can borrow') : null,
                          value: get(p, 'can_borrow') ? t('Yes') : t('No'),
                        },
                        {
                          label: get(p, 'can_borrow') ? t('Max borrow time') : null,
                          value: `${displayValue(get(p, 'max_borrow_time'))} ${t('hours')}`,
                        },
                        {
                          label: get(p, 'max_transfers') ? t('Device transfer limit') : null,
                          value: displayValue(get(p, 'max_transfers')),
                        },
                        { label: t('Prevent virtual machine'), value: get(p, 'prevent_vm') ? t('Yes') : t('No') },
                        {
                          label: shouldShowFeaturesTab ? t('Product features') : null,
                          value: <SelectedFeaturesTags features={policyFeatures} product={product} />,
                        },
                        {
                          label: shouldShowCustomFieldsTab ? t('Custom fields') : null,
                          value: <SelectedCustomFieldsTags cFields={policyCFs} />,
                        },
                      ]}
                    />
                  </div>
                );
              })}
            </div>
          );
        }}
        columns={[
          {
            expander: true,
            Header: t('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'bundle.data.product_name',
            Header: t('Bundle name'),
            className: 'text-center',
            headerClassName: 'text-center',
          },
          {
            accessor: 'bundle.data.short_code',
            Header: t('Bundle code'),
            className: 'text-center',
            headerClassName: 'text-center',
          },
          {
            className: 'text-center',
            Cell: cellData => (
              <button
                className="edit-button"
                onClick={() => handleBundleEdit(cellData.index)}
                type="button"
              >
                <IconEdit height="20px" width="20px" />
              </button>
            ),
            width: 50,
            show: showActionBtns,
          },
          {
            className: 'text-center',
            Cell: cellData => (
              <button
                className="delete-button"
                onClick={() => deleteBundleFromList(cellData.index)}
                type="button"
              >
                <IconDelete height="20px" width="20px" color="#ee5253" />
              </button>
            ),
            width: 50,
            show: showActionBtns,
          },
        ]}
        showPagination={false}
        minRows={2}
        noDataText={t('Add bundle to the list')}
      />
    </div>
  );
};

SelectedBundlesTable.propTypes = {
  deleteBundleFromList: PropTypes.func,
  handleBundleEdit: PropTypes.func,
  selectedBundles: PropTypes.array,
  showActionBtns: PropTypes.bool,
};

SelectedBundlesTable.defaultProps = {
  deleteBundleFromList: () => {},
  handleBundleEdit: () => {},
  selectedBundles: [],
  showActionBtns: true,
};

export default SelectedBundlesTable;
