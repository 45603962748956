import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ContentLoader,
  DirtyFormAlert,
  Label,
  Notice,
  Selector,
  SlidePane,
} from 'shared/components';
import {
  mapProductsToSelector,
  formatPolicies,
} from 'shared/helpers';
import { getProductsInBundle } from '../../actions';
import PolicySelector from './PolicySelector';
import './styles.scss';

const SelectBundleForm = ({
  closeCb,
  handleSubmit,
  isOpen,
  bundleToEdit,
  title,
  width,
}) => {
  const { t } = useTranslation();
  const isBundleChanged = useRef(false);
  const products = useSelector(state => get(state, 'user.details.products') || []);
  const userPolicies = useSelector(state => get(state, 'user.details.license_templates') || []);
  const companyID = useSelector(state => get(state, 'company.details.id'));
  const bundles = products.filter(p => p.is_bundle);
  const bundleOptions = mapProductsToSelector(bundles);

  const initialSelectedPolicies = bundleToEdit ? get(bundleToEdit, 'selected_bundle_policies') : [];

  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [selectedBundle, setSelectedBundle] = useState(get(bundleToEdit, 'bundle') || bundleOptions[0]);
  const [productsInBundleLoading, setProductsInBundleLoading] = useState(true);
  const [bundleProductError, setBundleProductError] = useState(false);
  const [productsInBundle, setProductsInBundle] = useState([]);
  const [selectedProductPolicies, setSelectedProductPolicies] = useState(initialSelectedPolicies);

  const selectedBundleID = get(selectedBundle, 'data.id');

  const setInitialState = (items = []) => {
    const productsList = [];
    const policiesList = [];
    let isMissing = false;
    items.forEach((i) => {
      const pr = products.find(p => p.id === i.id);
      const policies = userPolicies.filter(up => get(up, 'product.short_code') === get(pr, 'short_code'));
      if (!pr || !policies.length) {
        // FOR BUNDLE PRODUCTS LICENSE MANAGER HAS TO HAVE AT LEAST ONE POLICY PER PRODUCT IN BUNDLE
        isMissing = true;
        productsList.push(i);
        setBundleProductError(isMissing);
      } else {
        const mappedPolicies = formatPolicies(policies);
        pr.license_templates = mappedPolicies;
        productsList.push(pr);
        policiesList.push(mappedPolicies[0]);
      }
    });
    setProductsInBundle(productsList);
    if (!bundleToEdit || isBundleChanged.current) {
      setSelectedProductPolicies(policiesList);
    }
    setProductsInBundleLoading(false);
  };

  const handlePolicySelect = (policy) => {
    const policyProductID = get(policy, 'product.id');
    const filtered = selectedProductPolicies.filter(spp => get(spp, 'product.id') !== policyProductID);
    setSelectedProductPolicies([...filtered, policy]);
  };

  const getBundledProducts = useCallback(() => {
    setProductsInBundleLoading(true);
    getProductsInBundle(selectedBundleID)
      .then((res) => {
        const data = get(res, 'data') || [];
        setInitialState(data);
      })
      .catch(() => {
        setProductsInBundleLoading(false);
      });
  }, [selectedBundleID]);

  useEffect(() => {
    getBundledProducts();
  }, [getBundledProducts]);

  const handleBundleSelect = (val) => {
    const selectedB = bundleOptions.find(b => get(b, 'data.product_name') === val);
    setDirty(true);
    isBundleChanged.current = true;
    setSelectedBundle(selectedB);
  };

  const handleBundleSubmit = () => {
    handleSubmit({
      bundle: selectedBundle,
      selected_bundle_policies: selectedProductPolicies,
    });
  };

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormDisplay(true);
    } else {
      closeCb();
    }
  };

  const getTitle = () => (
    <div className="SelectBundleFormTitle">
      <div className="title">{title}</div>
      <div className="confirm-btn">
        <Button
          theme="success"
          onClick={handleBundleSubmit}
          disabled={productsInBundleLoading || !productsInBundle.length}
        >
          {t('Confirm')}
        </Button>
      </div>
    </div>
  );

  const isBundleProductCreated = bundles && Array.isArray(bundles) && bundles.length > 0;

  return (
    <SlidePane
      closeCb={handleClose}
      isOpen={isOpen}
      title={getTitle()}
      width={width}
    >
      <div className="SelectBundleForm">
        <div className="section-row">
          <Label inputId="bundle-select" text={t('Bundle name')} />
          <Selector
            options={bundleOptions}
            handleChange={handleBundleSelect}
            value={get(selectedBundle, 'value')}
            inputId="bundle-select"
            disabled={productsInBundleLoading}
          />
        </div>
        {!isBundleProductCreated && (
          <Notice theme="error" size="sm" title={t('Bundle products are not defined')}>
            <div className="bundle-prod-warning">
              <span>{t('Configure product bundle before issuing licenses')}</span>
              <NavLink to={`/${companyID}/products`}>{t('here')}</NavLink>
            </div>
          </Notice>
        )}
        <div className="section-row">
          {productsInBundleLoading ? (
            <div className="bundle-products-loading">
              <ContentLoader />
            </div>
          ) : (
            <div className="bundle-products-list">
              <div className="bundle-products-heading">
                {t('Products in a bundle')}
              </div>
              {bundleProductError ? (
                <Notice theme="error" title={t('Missing bundle products or license policies in your license manager permissions.')}>
                  <div>{`${t('Products defined in selected bundle are:')} ${productsInBundle.map(p => p.product_name).join(', ')}.`}</div>
                  <div>{t('Make sure that those products and at least one license policy per that product is enabled in your license manager permissions.')}</div>
                </Notice>
              ) : (
                productsInBundle.map(p => (
                  <PolicySelector
                    key={p.id}
                    product={p}
                    selectedPolicies={selectedProductPolicies}
                    handlePolicySelect={handlePolicySelect}
                  />
                ))
              )}
            </div>
          )}
        </div>
        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </SlidePane>
  );
};


SelectBundleForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  bundleToEdit: PropTypes.object,
  title: PropTypes.string.isRequired,
  width: PropTypes.string,
};

SelectBundleForm.defaultProps = {
  bundleToEdit: null,
  width: '80%',
};

export default SelectBundleForm;
