import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
    >
      <path
        d="M34.19,4H13.84a2.06,2.06,0,0,0-2.07,2.07V43.93A2.06,2.06,0,0,0,13.84,46H34.25a2.05,2.05,0,0,0,2.07-2.07V6.07A2.11,2.11,0,0,0,34.19,4ZM24,44.64a2.19,2.19,0,1,1,2.19-2.19A2.23,2.23,0,0,1,24,44.64Zm9.34-6.8a.72.72,0,0,1-.71.71H15.38a.73.73,0,0,1-.71-.71V7.67A.72.72,0,0,1,15.38,7H32.6a.72.72,0,0,1,.71.71V37.84Z"
      />
    </svg>
  </Base>
);
