import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Label,
  Selector,
  DescriptionTable,
  SelectedFeaturesTags,
  SelectedCustomFieldsTags,
} from 'shared/components';
import { licenseTypes } from 'shared/constants';
import { displayValue, displayMaxOveragesValue, capitalizeFirstLetter } from 'shared/helpers';
import './styles.scss';

const PolicySelector = ({
  product,
  selectedPolicies,
  handlePolicySelect,
}) => {
  const { t } = useTranslation();
  if (!product || !Array.isArray(selectedPolicies) || !selectedPolicies.length) {
    return null;
  }
  const productID = get(product, 'id');
  const productName = get(product, 'product_name');
  const productAuthMethod = get(product, 'authorization_method') || '';
  const isUserBasedProduct = productAuthMethod === 'user';
  const productPolicyOptions = get(product, 'license_templates');
  const selectedProductPolicy = selectedPolicies.find(sp => get(sp, 'product.id') === productID);
  const policyFeatures = get(selectedProductPolicy, 'license_product_feature_templates') || [];
  const policyCFs = get(selectedProductPolicy, 'license_custom_field_templates') || [];

  const shouldShowFeaturesTab = policyFeatures.length > 0;
  const shouldShowCustomFieldsTab = policyCFs.length > 0;
  const isTimeLimited = get(selectedProductPolicy, 'default_license_type') === licenseTypes.time_limited;
  const isSubscription = get(selectedProductPolicy, 'default_license_type') === licenseTypes.subscription;
  const isConsumption = get(selectedProductPolicy, 'default_license_type') === licenseTypes.consumption;
  const isTrial = get(selectedProductPolicy, 'allow_trial');
  const trialDays = get(selectedProductPolicy, 'trial_days');
  const trialValidity = `${trialDays} ${'days'}`;

  const handlePolicyChange = (val) => {
    const selected = productPolicyOptions.find(ppo => ppo.value === val);
    handlePolicySelect(selected);
  };

  return (
    <div className="PolicySelector">
      <div className="section-row">
        <Label inputId="product-policy" text={`${t('License policy for')} ${productName}`} />
        <Selector
          options={productPolicyOptions}
          handleChange={handlePolicyChange}
          value={selectedProductPolicy.value}
          inputId="product-policy"
        />
      </div>
      <div className="section-row">
        <DescriptionTable
          details={[
            {
              label: t('License Type'),
              value: displayValue(t(get(selectedProductPolicy, 'default_license_type'))),
            },
            {
              label: t('Max activations'),
              value: displayValue(get(selectedProductPolicy, 'max_activations')),
            },
            {
              label: isUserBasedProduct ? t('Max license users') : null,
              value: (get(selectedProductPolicy, 'unlimited_max_license_users') || get(selectedProductPolicy, 'max_license_users') === 0) ? t('Unlimited') : displayValue(get(selectedProductPolicy, 'max_license_users')),
            },
            {
              label: (isTimeLimited || isSubscription) ? t('Valid duration') : null,
              value: displayValue(get(selectedProductPolicy, 'valid_duration')),
            },
            {
              label: t('Is trial'),
              value: isTrial ? t('Yes') : t('No'),
            },
            {
              label: isTrial ? t('Trial days') : null,
              value: trialValidity,
            },
            // show consumption values
            {
              label: isConsumption ? t('Max consumptions') : null,
              value: displayValue(get(selectedProductPolicy, 'max_consumptions')),
            },
            {
              label: isConsumption ? t('Allow overages') : null,
              value: get(selectedProductPolicy, 'allow_overages') ? t('Yes') : t('No'),
            },
            {
              label: isConsumption ? t('Max overages') : null,
              value: displayMaxOveragesValue(selectedProductPolicy),
            },
            {
              label: isConsumption ? t('Reset consumption') : null,
              value: get(selectedProductPolicy, 'reset_consumption') ? t('Yes') : t('No'),
            },
            {
              label: isConsumption ? t('Consumption period') : null,
              value: displayValue(capitalizeFirstLetter(get(selectedProductPolicy, 'consumption_period'))),
            },
            // show everything else
            {
              label: get(selectedProductPolicy, 'enable_maintenance_period') ? t('Maintenance duration') : null,
              value: displayValue(get(selectedProductPolicy, 'maintenance_duration')),
            },
            {
              label: get(selectedProductPolicy, 'is_floating') ? t('Offline floating license') : null,
              value: get(selectedProductPolicy, 'is_floating') ? t('Yes') : t('No'),
            },
            {
              label: get(selectedProductPolicy, 'is_floating_cloud') ? t('Is floating cloud') : null,
              value: get(selectedProductPolicy, 'is_floating_cloud') ? t('Yes') : t('No'),
            },
            {
              label: (get(selectedProductPolicy, 'is_floating') || get(selectedProductPolicy, 'is_floating_cloud')) ? t('Max simultaneous license users') : null,
              value: displayValue(get(selectedProductPolicy, 'floating_users')),
            },
            {
              label: (get(selectedProductPolicy, 'is_floating') || get(selectedProductPolicy, 'is_floating_cloud')) ? t('Floating timeout') : null,
              value: `${displayValue(get(selectedProductPolicy, 'floating_timeout'))} min`,
            },
            {
              label: get(selectedProductPolicy, 'can_borrow') ? t('Can borrow') : null,
              value: get(selectedProductPolicy, 'can_borrow') ? t('Yes') : t('No'),
            },
            {
              label: get(selectedProductPolicy, 'can_borrow') ? t('Max borrow time') : null,
              value: `${displayValue(get(selectedProductPolicy, 'max_borrow_time'))} ${t('hours')}`,
            },
            {
              label: get(selectedProductPolicy, 'max_transfers') ? t('Device transfer limit') : null,
              value: displayValue(get(selectedProductPolicy, 'max_transfers')),
            },
            { label: t('Prevent virtual machine'), value: get(selectedProductPolicy, 'prevent_vm') ? t('Yes') : t('No') },
            {
              label: shouldShowFeaturesTab ? t('Product features') : null,
              value: <SelectedFeaturesTags features={policyFeatures} product={product} />,
            },
            {
              label: shouldShowCustomFieldsTab ? t('Custom fields') : null,
              value: <SelectedCustomFieldsTags cFields={policyCFs} />,
            },
          ]}
        />
      </div>
    </div>
  );
};

PolicySelector.propTypes = {
  product: PropTypes.object.isRequired,
  selectedPolicies: PropTypes.array.isRequired,
  handlePolicySelect: PropTypes.func.isRequired,
};

export default PolicySelector;
