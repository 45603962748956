import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  IconInvisible,
  IconVisible,
  InputErrorMessage,
  Spinner,
} from 'shared/components';
import './styles.scss';

const PasswordInput = ({
  disabled,
  error,
  handleChange,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  const [passVisible, setPasswordDisplay] = useState(false);

  const handleInputChange = e => handleChange(e.target.value);

  return (
    <div className="PasswordInput">
      <input
        className={error ? 'has-error' : ''}
        type={passVisible ? 'text' : 'password'}
        disabled={disabled}
        onChange={handleInputChange}
        {...rest}
      />
      {
        loading && <Spinner />
      }
      <button
        type="button"
        onClick={() => setPasswordDisplay(!passVisible)}
      >
        {!passVisible && <IconVisible color="#777" />}
        {passVisible && <IconInvisible color="#777" />}
      </button>
      <InputErrorMessage text={t(error)} />
    </div>
  );
};

PasswordInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
};

PasswordInput.defaultProps = {
  disabled: false,
  error: '',
  handleChange: () => {},
  loading: false,
};

export default PasswordInput;
