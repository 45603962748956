import get from 'lodash.get';

export const getCustomerData = (data, isExistingCustomer, field, existingCustomerField, fallback = '') => {
  if (isExistingCustomer) {
    return get(data, `existingCustomer.${existingCustomerField}`) || fallback;
  }
  return get(data, `${field}`) || fallback;
};

export const getValidDuration = (product) => {
  const licenseType = get(product, 'default_license_type');
  const isValidFrom = get(product, 'validity_type') === 'valid_from';
  if (licenseType === 'perpetual' || licenseType === 'consumption' || !isValidFrom) {
    return null;
  }
  if (licenseType === 'time-limited') {
    return get(product, 'valid_duration');
  }
  if (licenseType === 'subscription') {
    return get(product, 'subscription_duration');
  }
  return null;
};

export const getMaintenancePeriod = (product) => {
  const isEnabled = get(product, 'enable_maintenance_period');
  if (!isEnabled) {
    return null;
  }
  return get(product, 'maintenance_duration');
};

export const getConsumptionPeriod = (product) => {
  const isEnabled = get(product, 'reset_consumption');
  if (!isEnabled) {
    return null;
  }
  return get(product, 'consumption_period');
};
