import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InputErrorMessage, Spinner } from 'shared/components';
import './styles.scss';

const TextInput = ({
  disabled,
  error,
  handleChange,
  loading,
  ...rest
}) => {
  const { t } = useTranslation();
  const handleInputChange = e => handleChange(e.target.value);

  return (
    <div className="TextInput">
      <input
        className={error ? 'has-error' : ''}
        type="text"
        disabled={disabled}
        onChange={handleInputChange}
        {...rest}
      />
      {
        loading && <Spinner />
      }
      <InputErrorMessage text={t(error)} />
    </div>
  );
};

TextInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
};

TextInput.defaultProps = {
  disabled: false,
  error: '',
  handleChange: () => {},
  loading: false,
};

export default TextInput;
