// Fetching user
export const getUserInit = 'GET_USER_INIT';
export const getUserSuccess = 'GET_USER_SUCCESS';
export const getUserError = 'GET_USER_ERROR';
export const refreshUser = 'REFRESH_USER';

// Login
export const loginInit = 'LOGIN_INIT';
export const loginSuccess = 'LOGIN_SUCCESS';
export const loginError = 'LOGIN_ERROR';

// Logout
export const logoutInit = 'LOGOUT_INIT';
export const logoutSuccess = 'LOGOUT_SUCCESS';
export const logoutError = 'LOGOUT_ERROR';

// CUSTOM DOMAIN
export const setCustomDomainData = 'SET_CUSTOM_DOMAIN_DATA';

// manager permissions
export const getManagerPermissionsInit = 'GET_MANAGER_PERMISSIONS_INIT';
export const getManagerPermissionsError = 'GET_MANAGER_PERMISSIONS_ERROR';
export const getManagerPermissionsSuccess = 'GET_MANAGER_PERMISSIONS_SUCCESS';

// manager permissions
export const getManagerCounterInit = 'GET_MANAGER_COUNTER_INIT';
export const getManagerCounterError = 'GET_MANAGER_COUNTER_ERROR';
export const getManagerCounterSuccess = 'GET_MANAGER_COUNTER_SUCCESS';
