import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <g>
      <path
        d="M40,8.5H24A15.5134,15.5134,0,0,0,8.5,24V40A15.5134,15.5134,0,0,0,24,55.5H40A15.5134,15.5134,0,0,0,55.5,40V24A15.5134,15.5134,0,0,0,40,8.5Zm5.34,27.55a1.5,1.5,0,0,1-3,0V33.16H38.85v2.21a1.5,1.5,0,0,1-3,0V33.16H28.86a5.21,5.21,0,1,1,0-3H43.84a1.4981,1.4981,0,0,1,1.5,1.5ZM26.08,31.66a2.21,2.21,0,1,1-2.21-2.21A2.2107,2.2107,0,0,1,26.08,31.66Z"
      />
    </g>
  </Base>
);
