import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { refreshUser } from 'redux/user/actions';
import ProfileDetails from '../components/ProfileDetails';

const ProfileContainer = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'user.details'));

  const refreshUserData = data => dispatch(refreshUser(data));

  return <ProfileDetails user={user} refreshUser={refreshUserData} />;
};

export default ProfileContainer;
