import api from 'shared/api';

export const sendPasswordResetInit = (data) => {
  const url = 'api/v1/auth/password-reset-init/';
  return api.post(url, data);
};

export const checkPasswordResetKey = key => api.get(`/api/v1/auth/password-reset-check/?key=${key}`);

export const confirmPasswordReset = data => api.post('/api/v1/auth/password-reset-confirm/', data);
