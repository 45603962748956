import { formatManagerPermissions } from 'shared/helpers';
import * as actionTypes from './actionTypes';

const initialState = {
  checkingUser: false,
  loading: true,
  details: {},
  customDomain: null,
  managerPermissionsLoading: false,
  managerPermissions: [],
  counter: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.loginInit:
      return {
        ...state,
        checkingUser: true,
      };
    case actionTypes.loginSuccess:
      return {
        ...state,
        details: action.user,
        checkingUser: false,
      };
    case actionTypes.loginError:
      return {
        ...state,
        error: action.payload,
        checkingUser: false,
      };
    case actionTypes.logoutSuccess:
      return {
        ...state,
        details: {},
      };
    case actionTypes.logoutError:
      return {
        ...state,
        error: action.payload,
      };
    case actionTypes.getUserInit:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.getUserSuccess:
      return {
        ...state,
        loading: false,
        details: action.user,
      };
    case actionTypes.getUserError:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.refreshUser:
      return {
        ...state,
        details: action.user,
      };
    case actionTypes.setCustomDomainData:
      return {
        ...state,
        customDomain: action.data,
      };
    case actionTypes.getManagerPermissionsInit:
      return Object.assign({}, state, {
        managerPermissionsLoading: true,
      });
    case actionTypes.getManagerPermissionsSuccess:
      return Object.assign({}, state, {
        managerPermissionsLoading: false,
        managerPermissions: formatManagerPermissions(action.payload),
      });
    case actionTypes.getManagerPermissionsError:
      return Object.assign({}, state, {
        managerPermissionsLoading: false,
      });
    case actionTypes.getManagerCounterSuccess:
      return Object.assign({}, state, {
        counter: action.payload,
      });
    default:
      return state;
  }
}
