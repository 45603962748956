/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import { ProfileNavigation, LanguageSwitcher } from './components';
import './styles.scss';

const Header = ({ children }) => {
  const userCompany = useSelector(state => get(state, 'user.details.company'));
  const { logo, name, portal_data } = userCompany;
  const portalData = parseObject(portal_data, initialPortalData);
  const firstCharCompanyName = get(name, '[0]') || '';
  const headerBackground = get(portalData, 'topHeaderBackground') || '#555555';

  return (
    <header className="Header" style={{ background: headerBackground }}>
      <div className="Header-primary">
        <div className="logo">
          <NavLink to="/">
            {logo ? (
              <img src={logo} alt="company logo" className="company-logo" />
            ) : (
              <div className="company-logo-placeholder">
                {firstCharCompanyName.toUpperCase()}
              </div>
            )}
            <span className="company-name">{name}</span>
          </NavLink>
        </div>
        {children}
      </div>
      <div className="Header-secondary">
        <LanguageSwitcher />
        <ProfileNavigation />
      </div>
    </header>
  );
};

Header.propTypes = {
  children: PropTypes.element,
};

Header.defaultProps = {
  children: <div />,
};

export default Header;
