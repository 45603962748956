import api from 'shared/api';

export const getOrder = (orderID) => {
  const url = `/api/v1/orders/${orderID}/`;
  return api.get(url);
};

export const disableLicenses = (licenseIds) => {
  const url = '/api/v1/licenses/disable_bulk/';
  return api.post(url, licenseIds);
};

export const getOrderManagers = (orderID) => {
  const url = `/api/v1/orders/${orderID}/managers/`;
  return api.get(url);
};

export const addOrderManager = (orderID, data) => {
  const url = `/api/v1/orders/${orderID}/add_manager/`;
  return api.post(url, data);
};

export const removeOrderManager = (orderID, licenseUserID) => {
  const url = `/api/v1/orders/${orderID}/remove_manager/`;
  return api.post(url, { license_user_id: licenseUserID });
};

export const patchLicenseUser = (userId, data) => {
  const url = `/api/v1/users/${userId}/`;
  return api.patch(url, data);
};

export const setLicenseUserPassword = (userId, data) => {
  const url = `/api/v1/users/${userId}/set_password/`;
  return api.post(url, data);
};

export const checkExistingUser = (userEmail, companyId) => {
  const email = encodeURIComponent(userEmail);
  const url = `/api/v1/users/?true_email__iexact=${email}&company=${companyId}`;
  return api.get(url);
};
