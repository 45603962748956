import React from 'react';
import './styles.scss';

const LoadingIndicator = () => (
  <div className="LoadingIndicator">
    <div className="bounce1" />
    <div className="bounce2" />
    <div className="bounce3" />
  </div>
);

export default LoadingIndicator;
