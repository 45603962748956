import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import icon from '../sunk-ship.svg';
import './styles.scss';

const GlobalError = () => {
  const { t } = useTranslation();
  return (
    <div className="GlobalError">
      <div className="wrapper">
        <img src={icon} alt="Not found" height="100px" />
        <h2>
          {t('An error occured')}
        </h2>
        <p>
          {t('Our tech team has been notified, to restart browsing please click on a button below')}
        </p>
        <Button
          onClick={() => { window.location = '/'; }}
          theme="info"
        >
          {t('Restart browsing')}
        </Button>
      </div>
    </div>
  );
};

export default GlobalError;
