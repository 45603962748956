import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, IconMenu, Notification } from 'shared/components';
import { logout, logoutError, logoutSuccess } from 'redux/user/actions';
import './styles.scss';

const ProfileNavigation = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const popupRef = useRef();
  const [isMenuDisplayed, setMenuDisplay] = useState(false);

  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  const handleLogout = () => {
    toggleMenu();

    dispatch(logout())
      .then(() => {
        dispatch(logoutSuccess());
        Notification('success', t('You have been logged out'));
        history.push('/login');
      }).catch(() => {
        dispatch(logoutError());
        Notification('error', t('Error occured'), t('We could not log you out'));
      });
  };

  return (
    <div className="ProfileNavigation">
      <div className="profile-label">
        <div className="sidebar-item" onClick={toggleMenu} role="presentation">
          <IconMenu height="22px" width="22px" color="#fff" stroke="#fff" strokeWidth="2" />
        </div>
      </div>
      {isMenuDisplayed && (
        <Dropdown ref={popupRef} close={toggleMenu}>
          <ul className="links">
            <li>
              <button
                className="li-content"
                onClick={handleLogout}
                type="button"
              >
                {t('Logout')}
              </button>
            </li>
          </ul>
        </Dropdown>
      )}
    </div>
  );
};

export default ProfileNavigation;
