import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props} viewBox="0 0 95 95">
    <path
      d="M86,14H27.5v58.5H86V14z M80.1,66.6H33.4V19.9h46.7V66.6z M69.5,86H14V30.5h5.9v49.7h49.7V86z M53.8,46.2  H42.4v-5.9h11.4V28.9h5.9v11.4h11.4v5.9H59.7v11.4h-5.9V46.2z"
    />
  </Base>
);
