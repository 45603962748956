import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  displayValue,
} from 'shared/helpers';
import {
  DescriptionTable,
  ConfirmationPopup,
} from 'shared/components';
import './styles.scss';

const ExistingUserForm = ({
  closeCb,
  confirmCb,
  existingUser,
  loading,
}) => {
  const { t } = useTranslation();
  const existingUserEmail = get(existingUser, 'true_email');

  return (
    <ConfirmationPopup
      confirmCb={confirmCb}
      closeCb={closeCb}
      disabled={loading}
      title={t('User with this email already exist')}
      warning
    >
      <div className="ExistingUserForm">
        <div className="ExistingUserForm-message">
          {t('Do you want add this user as a license manager?')}
        </div>
        <DescriptionTable
          details={[
            {
              label: t('Email'),
              value: displayValue(existingUserEmail),
            },
            {
              label: t('First Name'),
              value: displayValue(get(existingUser, 'first_name')),
            },
            {
              label: t('Last Name'),
              value: displayValue(get(existingUser, 'last_name')),
            },
            {
              label: t('Phone number'),
              value: displayValue(get(existingUser, 'phone_number')),
            },
          ]}
        />
      </div>
    </ConfirmationPopup>
  );
};

ExistingUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  existingUser: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default ExistingUserForm;
