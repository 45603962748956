/* eslint-disable camelcase */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ConfirmationPopup,
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
  List,
  Notification,
  StatusCard,
  DeviceIcon,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  parseObject,
  getPortalData,
  hasPermission,
  formatOrderBy,
} from 'shared/helpers';
import { useAPI } from 'shared/hooks';
import { initialPortalData, defaultDateFormat } from 'shared/constants';
import { resetDeviceAction, revokeDeviceAction, releaseBorrowedDevice } from './actions';
import BorrowLicenseForm from '../../components/BorrowLicenseForm';
import DeviceActionMenu from '../../components/DeviceActionMenu';

const LicenseDevices = ({
  license,
  isLicenseUser,
}) => {
  const { t } = useTranslation();
  const licenseID = get(license, 'id');

  const userCompany = useSelector(state => get(state, 'user.details.company'));
  const permissionsList = useSelector(state => get(state, 'user.details.permissions'));
  const { portal_data } = userCompany;
  const portalData = parseObject(portal_data, initialPortalData);
  const allowUsersToResetLicense = getPortalData(portalData, 'allowUsersLicenseReset', initialPortalData);
  const allowUsersToBorrowLicense = getPortalData(portalData, 'allowUsersLicenseBorrow', initialPortalData);

  const shouldHideReset = isLicenseUser ? !allowUsersToResetLicense : !hasPermission(permissionsList, 'reset_device');
  const shouldHideBorrow = isLicenseUser ? !allowUsersToBorrowLicense : !hasPermission(permissionsList, 'borrow_license');
  const shouldHideRevoke = isLicenseUser ? true : !hasPermission(permissionsList, 'revoke_device');
  const canBorrow = get(license, 'can_borrow');

  const [page, setPage] = useState(0);
  const [currentSort, setCurrentSort] = useState([{ desc: true, id: 'last_check' }]);
  const [refetchCounter, setRefetchCounter] = useState(0);
  const [tableExpanded, setTableExpanded] = useState({});
  const [deviceToResetID, setDeviceToResetID] = useState(null);
  const [deviceToRevokeID, setDeviceToRevokeID] = useState(null);
  const [confirmationLoading, setConfirmationLoading] = useState(null);
  const [toManageBorrowDevice, setToManageBorrowDevice] = useState(null);
  const [isManageBorrowedConfirmationDisplayed, setManageBorrowedConfirmationDisplay] = useState(false);
  const [isReturnBorrowedConfirmationDisplayed, setReturnBorrowedConfirmationDisplay] = useState(false);
  const [borrowingLoading, setBorrowingLoading] = useState(false);

  const { isLoading: loading, value: devices = {} } = useAPI({
    url: `/api/v1/devices/?license=${licenseID}&limit=20&offset=${page * 20}&blacklisted=0${formatOrderBy(currentSort)}`,
    onError: () => Notification('error', t('Error occured'), t('There was an error while getting license devices')),
    dependenciesArray: [licenseID, page, currentSort, refetchCounter],
  });

  const handleDeviceReset = (device) => {
    const deviceId = get(device, 'id');
    const permission = hasPermission(permissionsList, 'reset_device');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
    } else {
      setDeviceToResetID(deviceId);
    }
  };

  const handleDeviceRevoke = (device) => {
    const deviceId = get(device, 'id');
    const permission = hasPermission(permissionsList, 'revoke_device');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
    } else {
      setDeviceToRevokeID(deviceId);
    }
  };

  const handleDevicesPageChange = newPage => setPage(newPage);

  const handleDevicesSortChange = newSort => setCurrentSort(newSort);

  const resetDevice = async (deviceID) => {
    setConfirmationLoading(true);

    try {
      await resetDeviceAction(deviceID);
      setPage(0);
      setRefetchCounter(refetchCounter + 1);
      setConfirmationLoading(false);
      setDeviceToResetID(null);
      Notification('success', t('Changes saved successfully'), t('License device has been reset'));
    } catch (err) {
      setConfirmationLoading(false);
      Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
    }
  };

  const revokeDevice = async (deviceID) => {
    setConfirmationLoading(true);

    try {
      await revokeDeviceAction(deviceID);
      setPage(0);
      setRefetchCounter(refetchCounter + 1);
      setDeviceToRevokeID(null);
      setConfirmationLoading(false);
      Notification('success', t('Changes saved successfully'), t('License device has been revoked'));
    } catch (err) {
      setConfirmationLoading(false);
      Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
    }
  };

  const returnBorrowedLicense = () => {
    const deviceID = get(toManageBorrowDevice, 'id');
    setBorrowingLoading(true);

    releaseBorrowedDevice(deviceID, { borrowed_until: null })
      .then(() => {
        setRefetchCounter(refetchCounter + 1);
        setToManageBorrowDevice(null);
        setBorrowingLoading(false);
        setReturnBorrowedConfirmationDisplay(false);
        Notification('success', t('Changes saved successfully'), t('License device has been returned'));
      })
      .catch(() => {
        setBorrowingLoading(false);
        Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
      });
  };

  const handleManageBorrowForm = (device) => {
    if (!canBorrow) {
      Notification('error', t('License does not allow borrowing'));
      return false;
    }

    setToManageBorrowDevice(device);
    setManageBorrowedConfirmationDisplay(true);
    return true;
  };

  const handleReturnBorrowForm = (device) => {
    if (!canBorrow) {
      Notification('error', t('License does not allow borrowing'));
      return false;
    }

    setToManageBorrowDevice(device);
    setReturnBorrowedConfirmationDisplay(true);
    return true;
  };

  const confirmEditBorrowed = () => {
    setToManageBorrowDevice(null);
    setManageBorrowedConfirmationDisplay(false);
    setRefetchCounter(refetchCounter + 1);
  };

  const devicesList = get(devices, 'results') || [];

  return (
    <div className="LicenseDevices">
      <List
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={(row) => {
          const isBorrowed = get(row, 'original.borrowed_until');
          return (
            <div className="SubComponent">
              <DescriptionTable
                details={[
                  {
                    label: isBorrowed ? t('Borrowed until') : null,
                    value: formatDate(get(row, 'original.borrowed_until'), defaultDateFormat),
                  },
                  {
                    label: t('First activated'),
                    value: formatDate(get(row, 'original.time_activated'), defaultDateFormat),
                  },
                  {
                    label: t('OS'),
                    value: displayValue(get(row, 'original.os')),
                  },
                  {
                    label: t('SDK build'),
                    value: displayValue(get(row, 'original.sdk_build_version')),
                  },
                  {
                    label: t('App version'),
                    value: displayValue(get(row, 'original.app_ver')),
                  },
                  {
                    label: t('Hostname'),
                    value: displayValue(get(row, 'original.hostname')),
                  },
                  {
                    label: t('External IP address'),
                    value: displayValue(get(row, 'original.external_ip')),
                  },
                  {
                    label: t('IP address'),
                    value: displayValue(get(row, 'original.ip')),
                  },
                  {
                    label: t('MAC address'),
                    value: displayValue(get(row, 'original.mac_address')),
                  },
                  {
                    label: t('Is virtual machine'),
                    value: get(row, 'original.is_vm') ? t('Yes') : t('No'),
                  },
                  {
                    label: t('VM info'),
                    value: displayValue(get(row, 'original.vm_info')),
                  },
                ]}
              />
            </div>
          );
        }}
        columns={[
          {
            expander: true,
            Header: t('Details'),
            headerClassName: 'text-center',
            width: 100,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none',
            },
          },
          {
            accessor: 'type',
            Header: t('Type'),
            className: 'text-center',
            headerClassName: 'text-center',
            width: 70,
            Cell: cellInfo => (
              <div className="device-type-icon">
                <DeviceIcon device={cellInfo.value} />
              </div>
            ),
          },
          {
            accessor: 'hardware_id',
            Header: t('Hardware ID'),
            Cell: cellInfo => displayValue(cellInfo.value),
          },
          {
            accessor: 'last_check',
            Header: t('Last checked'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140,
          },
          {
            accessor: 'device_active',
            Header: t('Status'),
            headerClassName: 'text-center',
            className: 'text-center',
            width: 100,
            Cell: cellInfo => (
              <StatusCard
                status={cellInfo.value ? 'success' : 'error'}
                text={cellInfo.value ? `${t('Active')}` : `${t('Inactive')}`}
              />
            ),
            maxWidth: 100,
          },
          {
            accessor: 'floating_in_use',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: t('In use'),
            width: 80,
            Cell: cellData => (cellData.value ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="red" height="14px" />),
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating'),
          },
          {
            id: 'is_borrowed',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: t('Is borrowed'),
            width: 120,
            Cell: (cellData) => {
              const isBorrowed = get(cellData, 'original.borrowed_until') || '';
              return (
                isBorrowed ? <IconCheckmark color="#10ac84" height="14px" /> : <IconDisabled color="red" height="14px" />
              );
            },
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating'),
          },
          {
            accessor: 'floating_last_seen',
            headerClassName: 'text-center',
            className: 'text-center',
            Header: t('Floating last check-in'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140,
            show: get(license, 'is_floating_cloud') || get(license, 'is_floating'),
          },
          {
            id: 'actions',
            className: 'select-container action-menu',
            Header: t('Actions'),
            headerClassName: 'text-center',
            Cell: rowData => (
              <DeviceActionMenu
                license={license}
                device={get(rowData, 'original')}
                handleDeviceResetForm={handleDeviceReset}
                handleManageBorrow={handleManageBorrowForm}
                handleReturnBorrow={handleReturnBorrowForm}
                handleDeviceRevoke={handleDeviceRevoke}
                isResetDisabled={shouldHideReset}
                isBorrowDisabled={shouldHideBorrow}
                isRevokeDisabled={shouldHideRevoke}
              />
            ),
            width: 70,
          },
        ]}
        data={devicesList}
        loading={loading}
        clickable={false}
        manual
        page={page}
        pages={devices ? Math.ceil(devices.count / 20) : 1}
        minRows={2}
        showPagination
        onPageChange={handleDevicesPageChange}
        onSortedChange={handleDevicesSortChange}
        defaultSorted={currentSort}
      />
      {
        deviceToResetID && (
          <ConfirmationPopup
            closeCb={() => setDeviceToResetID(null)}
            confirmCb={() => resetDevice(deviceToResetID)}
            title={t('Are you sure you want to reset this device?')}
            confirmText={t('Reset')}
            theme="warning"
            disabled={confirmationLoading}
          />
        )
      }
      {
        deviceToRevokeID && (
          <ConfirmationPopup
            closeCb={() => setDeviceToRevokeID(null)}
            confirmCb={() => revokeDevice(deviceToRevokeID)}
            title={t('Are you sure you want to revoke this device?')}
            confirmText={t('Revoke')}
            theme="warning"
            disabled={confirmationLoading}
          />
        )
      }
      {isManageBorrowedConfirmationDisplayed && (
        <BorrowLicenseForm
          closeCb={() => {
            setToManageBorrowDevice(null);
            setManageBorrowedConfirmationDisplay(false);
          }}
          confirmCb={confirmEditBorrowed}
          device={toManageBorrowDevice}
          license={license}
        />
      )}
      {isReturnBorrowedConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setToManageBorrowDevice(null);
            setReturnBorrowedConfirmationDisplay(false);
          }}
          confirmCb={returnBorrowedLicense}
          title={t('Are you sure you want to return this borrowed license?')}
          confirmText={t('Return')}
          theme="warning"
          disabled={borrowingLoading}
        />
      )}
    </div>
  );
};

LicenseDevices.propTypes = {
  license: PropTypes.object.isRequired,
  isLicenseUser: PropTypes.bool,
};

LicenseDevices.defaultProps = {
  isLicenseUser: false,
};

export default LicenseDevices;
