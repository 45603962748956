import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  ContentLoader,
  CustomPrompt,
} from 'shared/components';
import AppContainer from 'app/AppContainer';
import LoginContainer from 'login/LoginContainer';
import ResetPassword from 'password/ResetPassword';
import SetPassword from 'password/SetPassword';
import isAuthorized from 'app/isAuthorized';
import api from 'shared/api';
import { parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import LsLogo from 'assets/ls_logo.png';
import { getUser, setCustomDomainData } from './redux/user/actions';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(state => get(state, 'user.loading'));
  const user = useSelector(state => get(state, 'user.details'));
  const customDomainData = useSelector(state => get(state, 'user.customDomain'));
  const domain = get(window, 'location.hostname') || '';

  const initialDomainCheck = useCallback(async () => {
    try {
      const company = await api.get(`/api/v1/companies/get_id?domain=${domain}`);
      const customDomain = get(company, 'data');
      dispatch(setCustomDomainData(customDomain));
    } catch (error) {
      // console.log(error);
    }

    dispatch(getUser());
  }, [dispatch, getUser, domain]);

  useEffect(() => {
    initialDomainCheck();
  }, [initialDomainCheck]);

  if (loading) {
    return (
      <div className="App">
        <Helmet>
          <title>{`${t('User Portal')}`}</title>
        </Helmet>
        <ContentLoader text={t('Getting user details')} />
      </div>
    );
  }

  const portalData = parseObject(get(customDomainData, 'portal_data'), initialPortalData);
  const name = get(customDomainData, 'name') || 'LicenseSpring';
  const customTitle = get(portalData, 'loginTitle') || `${name} ${t('User Portal')}`;
  const favicon = get(customDomainData, 'logo') || get(customDomainData, 'favicon') || LsLogo;

  return (
    <div className="App">
      <Helmet>
        <title>{customTitle}</title>
        <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      </Helmet>
      <BrowserRouter
        getUserConfirmation={(message, callback) => (
          CustomPrompt(message, callback, t('Warning'))
        )}
      >
        <Switch>
          <Route path="/login" component={LoginContainer} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/set-password" component={SetPassword} />
          <Route path="/" component={isAuthorized(AppContainer, user)} />
        </Switch>
      </BrowserRouter>
      <ToastContainer
        autoClose={3000}
        closeButton={false}
        toastClassName="Notification-container"
      />
    </div>
  );
};

export default App;
