import get from 'lodash.get';
import {
  licenseUsageTypes,
} from 'shared/constants';

export const getLicenseUsageTypeOptions = (user) => {
  const userLicenseTypes = get(user, 'license_types') || [];
  const licenseTypesOptions = userLicenseTypes.sort().map((ult) => {
    const option = licenseUsageTypes.find(lut => lut.value === ult);
    return option;
  });
  licenseTypesOptions.unshift(licenseUsageTypes[0]);
  return licenseTypesOptions;
};
