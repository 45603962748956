/* eslint-disable no-useless-escape */
import get from 'lodash.get';
import moment from 'moment';
import {
  defaultDateTimeFormat,
  managerPermissionDescription,
  lettersRegex,
  numberRegex,
} from 'shared/constants';

export const slugify = (t, separator) => {
  let text = t.toString().toLowerCase().trim();

  const sets = [
    { to: 'a', from: '[ÀÁÂÃÄÅÆĀĂĄẠẢẤẦẨẪẬẮẰẲẴẶ]' },
    { to: 'c', from: '[ÇĆĈČ]' },
    { to: 'd', from: '[ÐĎĐÞ]' },
    { to: 'e', from: '[ÈÉÊËĒĔĖĘĚẸẺẼẾỀỂỄỆ]' },
    { to: 'g', from: '[ĜĞĢǴ]' },
    { to: 'h', from: '[ĤḦ]' },
    { to: 'i', from: '[ÌÍÎÏĨĪĮİỈỊ]' },
    { to: 'j', from: '[Ĵ]' },
    { to: 'ij', from: '[Ĳ]' },
    { to: 'k', from: '[Ķ]' },
    { to: 'l', from: '[ĹĻĽŁ]' },
    { to: 'm', from: '[Ḿ]' },
    { to: 'n', from: '[ÑŃŅŇ]' },
    { to: 'o', from: '[ÒÓÔÕÖØŌŎŐỌỎỐỒỔỖỘỚỜỞỠỢǪǬƠ]' },
    { to: 'oe', from: '[Œ]' },
    { to: 'p', from: '[ṕ]' },
    { to: 'r', from: '[ŔŖŘ]' },
    { to: 's', from: '[ßŚŜŞŠ]' },
    { to: 't', from: '[ŢŤ]' },
    { to: 'u', from: '[ÙÚÛÜŨŪŬŮŰŲỤỦỨỪỬỮỰƯ]' },
    { to: 'w', from: '[ẂŴẀẄ]' },
    { to: 'x', from: '[ẍ]' },
    { to: 'y', from: '[ÝŶŸỲỴỶỸ]' },
    { to: 'z', from: '[ŹŻŽ]' },
    { to: '-', from: '[·/_,:;\']' },
  ];

  sets.forEach((set) => {
    text = text.replace(new RegExp(set.from, 'gi'), set.to);
  });

  text = text.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w-]+/g, '') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

  if ((typeof separator !== 'undefined') && (separator !== '-')) {
    text = text.replace(/-/g, separator);
  }

  return text;
};

export const isValidDate = (dateString) => {
  const regEx = /^\d{4}-\d{2}-\d{2}$/;

  if (typeof dateString !== 'string') {
    return false;
  }

  if (!dateString.match(regEx)) {
    return false;
  }
  const d = new Date(dateString);
  if (Number.isNaN(d.getTime())) {
    return false;
  }
  return d.toISOString().slice(0, 10) === dateString;
};

export const formatDate = (val, formatType = defaultDateTimeFormat, fallback = '-') => {
  const isValid = moment(val).isValid();

  if (!val || !isValid) {
    return fallback;
  }
  return moment(val).format(formatType);
};

export const displayValue = (val, fallback = '-') => {
  if (!val && val !== 0) {
    return fallback;
  }
  return val;
};

export const getLicenseStatus = (enabled, active) => {
  if (!enabled) {
    return {
      positive: false,
      message: 'Disabled',
    };
  }
  return {
    positive: active,
    message: active ? 'Active' : 'Inactive',
  };
};

export const filterByProperty = (data, prop, query) => {
  if (!data || !Array.isArray(data)) {
    return [];
  }
  return data.filter((item) => {
    const property = item[prop];
    if (!property) {
      return false;
    }

    const lowercaseProp = property.toString().toLowerCase();
    const lowercaseQuery = query.toLowerCase();

    return lowercaseProp.indexOf(lowercaseQuery) !== -1;
  });
};

export const getLicenseById = (licenses, id) => {
  let result = [];

  if (Array.isArray(licenses) && licenses.length) {
    result = licenses.filter(item => item.id === id);
  }

  return get(result, '[0]');
};

export const generatePass = (pLength = 8) => {
  const keyListAlpha = 'abcdefghijklmnopqrstuvwxyz';
  const keyListInt = '123456789';
  const keyListSpec = '!@#_';
  let password = '';

  let len = Math.ceil(pLength / 2);
  len -= 1;
  const lenSpec = pLength - 2 * len;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    password += keyListAlpha.charAt(Math.floor(Math.random() * keyListAlpha.length));
    password += keyListInt.charAt(Math.floor(Math.random() * keyListInt.length));
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < lenSpec; i++) { password += keyListSpec.charAt(Math.floor(Math.random() * keyListSpec.length)); }

  password = password.split('').sort(() => 0.5 - Math.random()).join('');

  return password;
};

export const displayMaxOveragesValue = (data) => {
  const allowOverages = get(data, 'allow_overages');
  const maxOverages = get(data, 'max_overages');

  if (!allowOverages) {
    return '-';
  }

  if (maxOverages === 0) {
    return 'Unlimited';
  }

  return displayValue(maxOverages);
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return undefined;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const transformSort = (sortObject = []) => {
  const formattedSortObject = sortObject.map((sortObj) => {
    if (sortObj.id === 'license_key') {
      return {
        id: 'customLicenseSort',
        desc: sortObj.desc,
      };
    }
    if (sortObj.id === 'order_store_id') {
      return {
        id: 'order.store_id',
        desc: sortObj.desc,
      };
    }
    return sortObj;
  });

  const fieldsToTransform = [
    'customer.email',
    'customer.company_name',
    'customer.last_name',
    'customer.reference',
    'product.product_name',
    'order.store_id',
  ];
  const rewriteMap = {
    product: 'product.product_name',
  };
  return formattedSortObject.map((s) => {
    const field = rewriteMap[s.id] || s.id;
    if (fieldsToTransform.indexOf(field) >= 0) {
      return {
        id: field.split('.').join('__'),
        desc: s.desc,
      };
    }
    return s;
  });
};

export const isUserManager = (user) => {
  if (!user) { return false; }
  const isManager = get(user, 'is_manager');
  // const isIndependentManager = get(user, 'is_independent_manager');
  // return isManager || isIndependentManager;
  return isManager;
};

export const isJSON = (val) => {
  try {
    JSON.parse(val);
  } catch (e) {
    return false;
  }
  return true;
};

export const isJSONObject = (val) => {
  try {
    const obj = JSON.parse(val);
    return typeof obj === 'object';
  } catch (e) {
    return false;
  }
};

export const parseObject = (val, fallback = undefined) => {
  let data = {};

  if (!val || !isJSON(val)) {
    return fallback || data;
  }

  const result = JSON.parse(val);
  if (typeof result === 'object' && !Array.isArray(result)) {
    data = result;
  }

  return data;
};

export const copyText = (val) => {
  if (!val) {
    return false;
  }
  const textField = document.createElement('textarea');
  textField.innerText = val;
  document.body.appendChild(textField);
  textField.select();
  const result = document.execCommand('copy');
  textField.remove();
  return result;
};

export const getTableCustomerName = (data, fallback = '-') => {
  const firstName = get(data, 'customer.first_name') || '';
  const lastName = get(data, 'customer.last_name') || '';

  if (!firstName && !lastName) {
    return fallback;
  }

  if (!!firstName && !lastName) {
    return `${firstName}`;
  }

  if (!firstName && !!lastName) {
    return `${lastName}`;
  }

  const name = `${lastName}, ${firstName}`;
  return name.trim();
};

export const getPortalData = (portalData, property, fallbackData, fallback = false) => {
  // eslint-disable-next-line no-prototype-builtins
  const doesPropExist = typeof portalData === 'object' && portalData.hasOwnProperty(property);

  if (doesPropExist) {
    return portalData[property];
  }

  return fallbackData[property] || fallback;
};

export const hasPermission = (permissionsList, item) => {
  const isPermissionEnabled = permissionsList.find(p => p.code === item);
  return !!isPermissionEnabled;
};

export const formatManagerPermissions = (list) => {
  const data = list.map((i) => {
    const label = i.code.split('_').join(' ');
    const desc = managerPermissionDescription[i.code];
    return ({
      ...i,
      value: i.code,
      label: capitalizeFirstLetter(label),
      desc,
    });
  });
  return data;
};

export const mapProductsToSelector = (products, value = 'product_name') => {
  const productsOptions = [];
  if (!products) {
    return productsOptions;
  }
  if (Array.isArray(products)) {
    products.forEach((product) => {
      const option = {
        label: get(product, 'product_name'),
        value: get(product, `${value}`),
        data: product,
      };
      productsOptions.push(option);
    });
  }
  return productsOptions;
};

export const getDefaultLicensePolicy = (products, policies, productToEdit) => {
  let product;
  if (productToEdit) {
    product = productToEdit;
  } else {
    product = get(products, '[0]');
  }
  if (get(product, 'selected_license_policy')) {
    return get(product, 'selected_license_policy');
  }
  const productPolicies = policies.filter(p => get(p, 'product.id') === get(product, 'id'));
  const defaultPolicy = productPolicies.find(p => p.is_default);
  return defaultPolicy || productPolicies[0];
};


export const formatPolicyFeatures = policyFeatures => policyFeatures.map(pf => ({
  ...pf,
  name: get(pf, 'product_feature.name'),
  code: get(pf, 'product_feature.code'),
  label: get(pf, 'product_feature.name'),
  value: get(pf, 'product_feature.id'),
}));

export const formatPolicies = policies => policies.map(p => ({
  ...p,
  label: get(p, 'name'),
  value: get(p, 'code'),
}));

export const formatPolicyCFields = cfields => cfields.map(pf => ({
  ...pf,
  label: get(pf, 'product_custom_field.name'),
  value: get(pf, 'product_custom_field.id'),
  cf_value: get(pf, 'default_value'),
}));

export const mapFeaturesToSelector = (features) => {
  const featureOptions = [];

  if (Array.isArray(features)) {
    features.forEach((feature) => {
      const option = {
        label: get(feature, 'name'),
        value: get(feature, 'id'),
        code: get(feature, 'code'),
        feature_type: get(feature, 'feature_type'),
        expiry_date: get(feature, 'expiry_date') || null,
        max_consumption: get(feature, 'max_consumption'),
        allow_overages: get(feature, 'allow_overages'),
        max_overages: get(feature, 'max_overages'),
        reset_consumption: get(feature, 'reset_consumption'),
        consumption_period: get(feature, 'consumption_period'),
        data: feature,
      };
      featureOptions.push(option);
    });
  }
  return featureOptions;
};

export const mapPolicyProductFeatures = (product, policy) => {
  const productFeatures = mapFeaturesToSelector(get(product, 'product_features') || []);
  const policyFeatures = formatPolicyFeatures(get(policy, 'license_product_feature_templates') || []);
  const list = productFeatures.map((pf) => {
    const isPolicyFeature = policyFeatures.find(i => i.value === pf.value);
    if (isPolicyFeature) {
      return ({
        ...isPolicyFeature,
        name: get(pf, 'data.name'),
      });
    }
    return pf;
  });
  return list;
};

export const mapCustomFieldsToSelector = (product) => {
  const fieldsOptions = [];
  const customFields = get(product, 'custom_fields') || [];

  if (Array.isArray(customFields)) {
    customFields.forEach((cf) => {
      const option = {
        label: get(cf, 'name'),
        value: get(cf, 'id'),
        cf_value: get(cf, 'default_value'),
        data: cf,
      };
      fieldsOptions.push(option);
    });
  }
  return fieldsOptions;
};

export const mapPolicyCustomFields = (product, policy) => {
  const productCFields = mapCustomFieldsToSelector(product);
  const policyCFields = formatPolicyCFields(get(policy, 'license_custom_field_templates') || []);
  const list = productCFields.map((pf) => {
    const isPolicyFeature = policyCFields.find(i => i.value === pf.value);
    if (isPolicyFeature) {
      return isPolicyFeature;
    }
    return pf;
  });
  return list;
};

export const getCharsFromString = (value, fallback = '') => {
  if (!value || typeof value !== 'string') {
    return fallback;
  }
  const charsFromString = value.match(lettersRegex);

  if (!charsFromString || charsFromString.length === 0) {
    return fallback;
  }

  return charsFromString.join();
};

export const getNumbersFromString = (value, fallback = '') => {
  if (!value || typeof value !== 'string') {
    return fallback;
  }
  const numbersFromString = value.match(numberRegex);

  if (!numbersFromString || numbersFromString.length === 0) {
    return fallback;
  }

  return numbersFromString.join();
};

export const getErrMsg = (err, field = 'message') => {
  const data = get(err, 'response.data');
  const dataJson = parseObject(data, data);
  return get(dataJson, `${field}`) || get(dataJson, 'detail') || '';
};

export const getParameterByName = (name, url) => {
  const value = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${value}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const formatOrderBy = (orderBy) => {
  if (!orderBy || !Array.isArray(orderBy) || !orderBy.length) {
    return '';
  }
  const values = `${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}`;
  const cleanList = values.split(',').filter(Boolean).join(',');
  return `&order_by=${cleanList}`;
};

export const getTotalLicenseIssued = (counter = []) => {
  const count = counter.reduce((total, item) => total + get(item, 'created_licenses_count') || 0, 0);
  return count;
};

export const getRemainingLicenses = (productID, counter = []) => {
  if (!productID) {
    return 0;
  }
  const product = counter.find(i => get(i, 'product.id') === productID);
  if (!product) {
    return 0;
  }
  const used = get(product, 'created_licenses_count');
  const limit = get(product, 'num_of_licenses');
  if (limit === null) {
    return null;
  }
  const remaining = limit - used;
  return remaining;
};
