import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { NavLink as Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Notification } from 'shared/components';
// import { defaultDisabledFeatureMessage } from 'shared/constants';
import './styles.scss';

const NavLink = ({
  children,
  disabled,
  disabledMessage,
  to,
  ...props
}) => {
  const { t } = useTranslation();
  const handleClick = useCallback(
    (e) => {
      e.preventDefault(e);
      Notification('error', t(disabledMessage));
    },
  );

  return (
    <Link // eslint-disable-line jsx-a11y/anchor-is-valid
      onClick={disabled ? handleClick : undefined}
      to={disabled ? '#' : to}
      {...props}
    >
      {children}
    </Link>
  );
};

NavLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  to: PropTypes.string.isRequired,
};

NavLink.defaultProps = {
  disabled: false,
  disabledMessage: '',
};

export default NavLink;
