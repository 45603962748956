import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  generatePass,
} from 'shared/helpers';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
} from 'shared/validation';
import { setLicenseUserPassword } from 'orders/OrderContainer/actions';

const OrderManagerPasswordForm = ({
  closeCb,
  refetchManagers,
  manager,
}) => {
  const { t } = useTranslation();
  const managerToEditID = get(manager, 'id');

  const [isLoading, setLoading] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false);
  const [password, setPassword] = useState(generatePass());
  const [passwordError, setPasswordError] = useState('');

  const handlePasswordValidation = async () => {
    setLoading(true);
    let errors;
    try {
      errors = await validateRequiredValue(password);
      setPasswordError(errors);
    } catch (err) {
      // console.log(err);
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isPasswordValid = await handlePasswordValidation();
    return isPasswordValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    setLoading(true);
    const data = { password };

    setLicenseUserPassword(managerToEditID, data)
      .then(() => {
        refetchManagers();
        closeCb();
        Notification('success', t('Changes saved successfully'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
      });
    return true;
  };

  const handleClose = () => {
    if (!isDirty) { return closeCb(); }
    return setDirtyFormDisplay(true);
  };

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size="sm"
      title={t('Change password')}
    >
      <form className="OrderManagerPasswordForm" onSubmit={handleSubmit}>
        <div className="form-row">
          <Label text={t('Email')} inputId="email" />
          <div className="TextInput">
            <input type="text" value={get(manager, 'true_email')} disabled />
          </div>
        </div>
        <div className="form-row">
          <Label text={t('Password')} inputId="password" />
          <TextInput
            id="password"
            value={password}
            error={passwordError}
            handleChange={(val) => {
              setDirty(true);
              setPassword(val);
              debouncedValidateRequiredValue(val).then(err => setPasswordError(err));
            }}
          />
        </div>
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  );
};

OrderManagerPasswordForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchManagers: PropTypes.func.isRequired,
  manager: PropTypes.object.isRequired,
};

export default OrderManagerPasswordForm;
