import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Selector } from 'shared/components';

function LanguageSwitcher() {
  const { i18n, t } = useTranslation();
  const [selectedLang, setSelectedLang] = useState(i18n.language);

  const languageOptions = [
    {
      value: 'en',
      label: t('English'),
    },
    {
      value: 'es',
      label: t('Spanish'),
    },
    {
      value: 'fr',
      label: t('French'),
    },
    {
      value: 'de',
      label: t('German'),
    },
  ];

  const handleLanguageChange = (val) => {
    setSelectedLang(val);
    i18n.changeLanguage(val);
  };

  return (
    <div className="LanguageSelector">
      <Selector
        handleChange={handleLanguageChange}
        options={languageOptions}
        value={selectedLang}
      />
    </div>
  );
}
export default LanguageSwitcher;
