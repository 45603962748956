import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Notification,
  PasswordInput,
  TextInput,
} from 'shared/components';
import { parseObject } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import { login, loginError, loginSuccess } from 'redux/user/actions';
import './styles.scss';

const LoginContainer = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isCheckingUser = useSelector(state => get(state, 'user.checkingUser'));
  const user = useSelector(state => get(state, 'user.details'));
  const customDomain = useSelector(state => get(state, 'user.customDomain'));

  const whitelabeledCompanyCode = get(customDomain, 'code') || '';
  const whitelabeledCompanyName = get(customDomain, 'name') || '';
  const whitelabeledCompanyLogo = get(customDomain, 'logo');

  const [company, setCompany] = useState(whitelabeledCompanyCode);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const getLogo = () => {
    if (!customDomain) {
      return (
        <img src="img/ls-logo.svg" alt="logo" height="90px" />
      );
    }
    if (whitelabeledCompanyLogo) {
      return (
        <img src={whitelabeledCompanyLogo} alt="logo" height="90px" />
      );
    }
    return null;
  };

  useEffect(() => {
    if (get(user, 'id')) {
      history.push('/');
    }
  }, [user, history]);

  const handleLogin = (e) => {
    e.preventDefault();

    if (isCheckingUser) {
      return;
    }

    const auth = `${email}|${company}`;

    const loginData = {
      email: auth,
      password,
    };

    dispatch(login(loginData))
      .then((res) => {
        dispatch(loginSuccess(get(res, 'data.user')));
        history.push('/');
        Notification('success', t('You have been logged in'));
      })
      .catch((err) => {
        dispatch(loginError(err));
        Notification('error', t('Unable to login'), t('You provided wrong credentials'));
      });
  };

  const portalData = parseObject(get(customDomain, 'portal_data'), initialPortalData);
  const customTitle = get(portalData, 'loginTitle') || `${whitelabeledCompanyName} ${t('User Portal')}`;
  const customMessage = get(portalData, 'loginMessage') || '';

  return (
    <div className="LoginContainer">
      <div className="form-container">
        <form
          className="login-form"
          id="login-form"
          onSubmit={e => handleLogin(e)}
        >
          <fieldset
            disabled={isCheckingUser}
          >
            <div className="row logo-cont">
              {getLogo()}
              {whitelabeledCompanyName && (
                <div className="company-name">
                  {customTitle}
                </div>
              )}
              {customMessage && (
                <div className="company-message">
                  {customMessage}
                </div>
              )}
            </div>
            {!whitelabeledCompanyCode && (
              <div className="row input-cont">
                <Label
                  inputId="login-email"
                  text={t('Company ID')}
                />
                <TextInput
                  disabled={isCheckingUser}
                  handleChange={val => setCompany(val)}
                  id="login-company"
                  type="text"
                  value={company}
                />
              </div>
            )}
            <div className="row input-cont">
              <Label
                inputId="login-email"
                text={t('Email')}
              />
              <TextInput
                disabled={isCheckingUser}
                handleChange={val => setEmail(val)}
                id="login-email"
                type="email"
                value={email}
              />
            </div>
            <div className="row input-cont">
              <Label
                inputId="login-pass"
                text={t('Password')}
              />
              <PasswordInput
                disabled={isCheckingUser}
                handleChange={val => setPassword(val)}
                id="login-pass"
                value={password}
              />
            </div>
            <div className="row btn-cont">
              <Button
                disabled={isCheckingUser}
                size="lg"
                theme="success"
                type="submit"
              >
                {t('Login')}
              </Button>
            </div>
            <div className="row forgot-pass">
              <a className="login-forgot" href="/reset-password">
                {t('Forgot your password?')}
              </a>
            </div>
            {/* <div className="row contact">
              <p className="contact-us">
                {t('If you are having trouble logging in to your account, please')}
                <a href="https://licensespring.zendesk.com/hc/en-us/requests/new">
                  {t('contact support')}
                </a>
              </p>
            </div> */}
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default LoginContainer;
