import api from 'shared/api';
import { formatOrderBy } from 'shared/helpers';
import * as actionTypes from './actionTypes';

const getLicensesInit = () => ({
  type: actionTypes.getLicensesInit,
});

const getLicensesSuccess = res => ({
  type: actionTypes.getLicensesSuccess,
  licenses: res,
});

const getLicensesError = res => ({
  type: actionTypes.getLicensesError,
  licenses: res,
});

export const getLicenses = (page, query, queryType, orderBy, filters = '') => (dispatch) => {
  dispatch(getLicensesInit());
  const queryUrl = query ? `&${queryType}=${query}` : '';
  const orderUrl = formatOrderBy(orderBy);
  const url = `/api/v1/licenses/?limit=20&offset=${page ? page * 20 : 0}${queryUrl}${orderUrl}${filters}&bundle_license__isnull=1`;
  return api.get(url).then(
    res => dispatch(getLicensesSuccess(res.data)),
    res => dispatch(getLicensesError(res.data)),
  );
};

export const setLicenseListType = data => ({
  type: actionTypes.setLicenseListType,
  payload: data,
});
