import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  InputErrorMessage, IconInfo, Tooltip,
} from 'shared/components';
import './styles.scss';

const RadioBtn = ({
  disabled,
  description,
  error,
  inputId,
  value,
  name,
  label,
  handleChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const handleInputChange = e => handleChange(e.target.value, e);

  return (
    <div className="RadioBtn">
      <label htmlFor={inputId}>
        <input
          type="radio"
          name={name}
          className={error ? 'has-error' : ''}
          id={inputId}
          value={value}
          onChange={handleInputChange}
          {...rest}
        />
        <span>
          {label}
          {description && (
            <Tooltip content={description} active>
              <span>
                <IconInfo height="12px" width="12px" />
              </span>
            </Tooltip>
          )
          }
        </span>
      </label>
      <InputErrorMessage text={t(error)} />
    </div>
  );
};

RadioBtn.propTypes = {
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  inputId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

RadioBtn.defaultProps = {
  disabled: false,
  description: null,
  label: '',
  error: '',
  handleChange: () => {},
};

export default RadioBtn;
