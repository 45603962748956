import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import './styles.scss';

const ContentLoader = ({ text }) => {
  const { t } = useTranslation();
  return (
    <div className="ContentLoader">
      <div className="ContentLoader-spinner" />
      <div className="ContentLoader-text">
        {text || t('Loading')}
      </div>
    </div>
  );
};

ContentLoader.propTypes = {
  text: PropTypes.string,
};

ContentLoader.defaultProps = {
  text: '',
};

export default ContentLoader;
