import React, { useEffect, useState } from 'react';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  Notification,
  TextInput,
} from 'shared/components';
import {
  validateEmail,
  debouncedValidateEmail,
} from 'shared/validation';
import { parseObject, getErrMsg } from 'shared/helpers';
import { initialPortalData } from 'shared/constants';
import { sendPasswordResetInit } from '../actions';
import './styles.scss';

const ResetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const isCheckingUser = useSelector(state => get(state, 'user.checkingUser'));
  const user = useSelector(state => get(state, 'user.details'));
  const customDomain = useSelector(state => get(state, 'user.customDomain'));

  const whitelabeledCompanyCode = get(customDomain, 'code') || '';
  const whitelabeledCompanyName = get(customDomain, 'name') || '';
  const whitelabeledCompanyLogo = get(customDomain, 'logo');

  const [company, setCompany] = useState(whitelabeledCompanyCode);
  const [isLoading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [isSubmitted, setSubmitted] = useState(false);

  const getLogo = () => {
    if (!customDomain) {
      return (
        <img src="img/ls-logo.svg" alt="logo" height="90px" />
      );
    }
    if (whitelabeledCompanyLogo) {
      return (
        <img src={whitelabeledCompanyLogo} alt="logo" height="90px" />
      );
    }
    return null;
  };

  useEffect(() => {
    if (get(user, 'id')) {
      history.push('/');
    }
  }, [user, history]);

  const handleEmailValidation = async () => {
    setLoading(true);
    let errors;
    try {
      errors = await validateEmail(email);
      setEmailError(errors);
    } catch (err) {
      // console.log(err);
    }
    setLoading(false);
    if (errors) { return false; }
    return true;
  };

  const isFormValid = async () => {
    const isEmailValid = await handleEmailValidation();
    return isEmailValid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const isValid = await isFormValid();
    if (!isValid || isLoading) {
      return false;
    }

    if (isCheckingUser) {
      return false;
    }

    const resetPasswordData = {
      email,
      company_code: company,
    };

    sendPasswordResetInit(resetPasswordData)
      .then(() => {
        setSubmitted(true);
        Notification('success', t('Password request has been sent.'));
      })
      .catch((err) => {
        const errMsg = getErrMsg(err, 'error');
        Notification('error', t('Error occured'), errMsg);
      });
    return true;
  };

  const portalData = parseObject(get(customDomain, 'portal_data'), initialPortalData);
  const customTitle = get(portalData, 'loginTitle') || `${whitelabeledCompanyName} ${t('User Portal')}`;

  if (isSubmitted) {
    return (
      <div className="ResetPassword">
        <div className="form-container">
          <div className="login-form">
            <fieldset>
              <div className="row logo-cont">
                {getLogo()}
                {whitelabeledCompanyName && (
                  <div className="company-name">
                    {customTitle}
                  </div>
                )}
              </div>
              <div className="row title">
                {t('Password reset')}
              </div>
              <div className="row description">
                {t('Check your email and open the link we sent to continue.')}
              </div>
              <div className="row forgot-pass submitted">
                <a className="login-forgot" href="/login">
                  {t('Back to login')}
                </a>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ResetPassword">
      <div className="form-container">
        <form
          className="login-form"
          id="login-form"
          onSubmit={e => handleLogin(e)}
        >
          <fieldset
            disabled={isCheckingUser}
          >
            <div className="row logo-cont">
              {getLogo()}
              {whitelabeledCompanyName && (
                <div className="company-name">
                  {customTitle}
                </div>
              )}
            </div>
            <div className="row title">
              {t('Password reset')}
            </div>
            <div className="row description">
              {t('Enter your email and we will send you a link to reset your password.')}
            </div>
            {!whitelabeledCompanyCode && (
              <div className="row input-cont">
                <Label
                  inputId="login-email"
                  text={t('Company ID')}
                />
                <TextInput
                  disabled={isCheckingUser || isLoading}
                  handleChange={val => setCompany(val)}
                  id="login-company"
                  type="text"
                  value={company}
                />
              </div>
            )}
            <div className="row input-cont">
              <Label
                inputId="login-email"
                text={t('Email')}
              />
              <TextInput
                disabled={isCheckingUser || isLoading}
                handleChange={(val) => {
                  setEmail(val);
                  debouncedValidateEmail(val).then(err => setEmailError(err));
                }}
                id="login-email"
                type="email"
                value={email}
                error={emailError}
              />
            </div>
            <div className="row btn-cont">
              <Button
                disabled={isCheckingUser || isLoading}
                size="lg"
                theme="success"
                type="submit"
              >
                {t('Submit')}
              </Button>
            </div>
            <div className="row forgot-pass">
              <a className="login-forgot" href="/login">
                {t('Back to login')}
              </a>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
