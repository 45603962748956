import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ConfirmationPopup,
  HiddenPasswordField,
  List,
  Notification,
} from 'shared/components';
import {
  displayValue,
  hasPermission,
} from 'shared/helpers';
import {
  BatchLicenseAssign,
  LicenseUserForm,
  LicenseUserPasswordForm,
  RemoveLicenseUserForm,
  LicenseUsersActionMenu,
  LicenseUserMaxActivations,
  EditLicenseUser,
} from 'licenses/components';
import { setLicenseUserActivations } from './actions';
import './styles.scss';

const LicenseUsers = ({
  getLicense,
  license,
}) => {
  const { t } = useTranslation();
  const licenseID = get(license, 'id');
  const permissionsList = useSelector(state => get(state, 'user.details.permissions'));

  const [showLicenseUserForm, setLicenseUserForm] = useState(false);
  const [showLicenseUserPasswordForm, setLicenseUserPasswordForm] = useState(false);
  const [licenseUserPasswordChange, setLicenseUserPasswordChange] = useState(null);
  const [showLicenseUserRemoveForm, setLicenseUserRemoveForm] = useState(false);
  const [showLicenseUserEditForm, setLicenseUserEditForm] = useState(false);
  const [showLicenseUserMaxActivationsForm, setLicenseUserMaxActivationsForm] = useState(false);
  const [showActivationsResetPopup, setActivationsResetPopupDisplay] = useState(false);
  const [licenseUserToEdit, setLicenseUserToEdit] = useState(null);
  const [isBatchLicenseAssignDisplayed, setBatchLicenseDisplay] = useState(false);

  const handleModalSubmit = () => {
    getLicense();
    setLicenseUserRemoveForm(false);
    setLicenseUserForm(false);
    setLicenseUserPasswordForm(false);
  };

  const maxLicenseUsers = get(license, 'max_license_users');
  const isUnlimitedLicenseUsers = get(license, 'max_license_users') === 0;
  const licenseUsers = get(license, 'license_users') || [];
  const currentLicenseUsersCount = licenseUsers.length;
  const maxLicenseUserStatus = `${currentLicenseUsersCount} / ${isUnlimitedLicenseUsers ? t('Unlimited') : maxLicenseUsers}`;

  const checkUserAssignAllowed = () => {
    const permission = hasPermission(permissionsList, 'manage_license_users');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
      return false;
    }
    if (!isUnlimitedLicenseUsers && (currentLicenseUsersCount >= Number(maxLicenseUsers))) {
      Notification('error', t('Max license users number reached'));
      return false;
    }
    return true;
  };

  const handleUserAssign = () => {
    const isAllowed = checkUserAssignAllowed();
    if (!isAllowed) {
      return false;
    }
    setLicenseUserForm(true);
    return true;
  };

  const handleBatchUserAssign = () => {
    const isAllowed = checkUserAssignAllowed();
    if (!isAllowed) {
      return false;
    }
    setBatchLicenseDisplay(true);
    return true;
  };

  const handleUserDelete = (rowData) => {
    const permission = hasPermission(permissionsList, 'manage_license_users');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
      return false;
    }
    setLicenseUserToEdit(rowData.original);
    setLicenseUserRemoveForm(true);
    return true;
  };

  const handleUserEdit = (rowData) => {
    const permission = hasPermission(permissionsList, 'manage_license_users');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
      return false;
    }
    setLicenseUserToEdit(rowData.original);
    setLicenseUserEditForm(true);
    return true;
  };

  const handleUserPasswordChange = (rowData) => {
    const permission = hasPermission(permissionsList, 'manage_license_user_credentials');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
      return false;
    }
    setLicenseUserPasswordChange(rowData.original);
    setLicenseUserPasswordForm(true);
    return true;
  };

  const handleUserMaxActivations = (rowData) => {
    const permission = hasPermission(permissionsList, 'manage_license_users');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
      return false;
    }
    setLicenseUserToEdit(rowData.original);
    setLicenseUserMaxActivationsForm(true);
    return true;
  };

  const handleResetTotalActivations = (rowData) => {
    const permission = hasPermission(permissionsList, 'manage_license_users');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
      return false;
    }
    setLicenseUserToEdit(rowData.original);
    setActivationsResetPopupDisplay(true);
    return true;
  };

  const resetTotalActivations = () => {
    const userID = get(licenseUserToEdit, 'id');
    const data = {
      [userID]: { reset_total_activations: true },
    };
    setLicenseUserActivations(licenseID, data)
      .then(() => {
        getLicense();
        setActivationsResetPopupDisplay(false);
        setLicenseUserToEdit(null);
        Notification('success', t('Changes saved successfully'), '');
      })
      .catch(() => {
        Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
      });
  };

  return (
    <div className="LicenseUsers">
      <div className="list-header">
        <div>
          <Button
            theme="info"
            size="sm"
            onClick={handleUserAssign}
          >
            {t('Assign license user')}
          </Button>
          <Button
            size="sm"
            theme="default"
            onClick={handleBatchUserAssign}
          >
            {t('Batch license assign')}
          </Button>
        </div>
        <div>
          <div className="max-license-users-count">{`${t('Available number of users')}: ${maxLicenseUserStatus}`}</div>
        </div>
      </div>
      <List
        columns={[
          {
            accessor: 'true_email',
            Header: t('Email'),
            Cell: cellData => displayValue(cellData.value),
            minWidth: 200,
          },
          {
            accessor: 'first_name',
            Header: t('First Name'),
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'last_name',
            Header: t('Last Name'),
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'phone_number',
            Header: t('Phone number'),
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'is_initial_password',
            Header: t('Initial password'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: (cellData) => {
              if (!cellData.value) { return t('N/A'); }
              const initialPass = get(cellData, 'original.initial_password');
              return <HiddenPasswordField value={initialPass} fallback={t('N/A')} />;
            },
          },
          {
            accessor: 'max_activations',
            Header: t('Max activations'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            accessor: 'total_activations',
            Header: t('Total activations'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => displayValue(cellData.value),
          },
          {
            id: 'actions',
            className: 'select-container action-menu',
            Header: t('Actions'),
            headerClassName: 'text-center',
            Cell: rowData => (
              <LicenseUsersActionMenu
                user={get(rowData, 'original')}
                handlePasswordChange={() => {
                  handleUserPasswordChange(rowData);
                }}
                handleMaxActivationsChange={() => {
                  handleUserMaxActivations(rowData);
                }}
                handleResetTotalActivations={() => {
                  handleResetTotalActivations(rowData);
                }}
                handleRemoveLicenseUser={() => {
                  handleUserDelete(rowData);
                }}
                handleEditLicenseUser={() => {
                  handleUserEdit(rowData);
                }}
              />
            ),
            width: 70,
          },
        ]}
        data={get(license, 'license_users') || []}
        minRows={2}
        pageSize={20}
      />
      {
        showLicenseUserForm && (
          <LicenseUserForm
            title={t('Assign license user')}
            closeCb={() => setLicenseUserForm(false)}
            confirmCb={handleModalSubmit}
            license={license}
          />
        )
      }
      {
        showLicenseUserPasswordForm && (
          <LicenseUserPasswordForm
            title={t('Change user password')}
            closeCb={() => setLicenseUserPasswordForm(false)}
            confirmCb={handleModalSubmit}
            licenseUser={licenseUserPasswordChange}
          />
        )
      }
      {
        showLicenseUserRemoveForm && (
          <RemoveLicenseUserForm
            closeCb={() => {
              setLicenseUserToEdit(null);
              setLicenseUserRemoveForm(false);
            }}
            confirmCb={handleModalSubmit}
            license={license}
            userToRemove={licenseUserToEdit}
          />
        )
      }
      {
        showLicenseUserEditForm && (
          <EditLicenseUser
            user={licenseUserToEdit}
            userID={get(licenseUserToEdit, 'id')}
            closeCb={() => {
              setLicenseUserToEdit(null);
              setLicenseUserEditForm(false);
            }}
            refetchLicense={getLicense}
          />
        )
      }
      {
        isBatchLicenseAssignDisplayed && (
          <BatchLicenseAssign
            closeCb={() => setBatchLicenseDisplay(false)}
            confirmCb={() => {
              getLicense();
              setBatchLicenseDisplay(false);
            }}
            license={license}
          />
        )
      }
      {showLicenseUserMaxActivationsForm && (
        <LicenseUserMaxActivations
          closeCb={() => {
            setLicenseUserToEdit(null);
            setLicenseUserMaxActivationsForm(false);
          }}
          user={licenseUserToEdit}
          licenseID={get(license, 'id')}
          refetchLicense={getLicense}
        />
      )}
      {showActivationsResetPopup && (
        <ConfirmationPopup
          closeCb={() => {
            setLicenseUserToEdit(null);
            setActivationsResetPopupDisplay(false);
          }}
          confirmCb={resetTotalActivations}
          title={t('Are you sure you want to reset this users total license activations count?')}
          confirmText={t('Reset')}
          theme="error"
        >
          {get(licenseUserToEdit, 'true_email')}
        </ConfirmationPopup>
      )}
    </div>
  );
};

LicenseUsers.propTypes = {
  getLicense: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
};

export default LicenseUsers;
