import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <path
      d="M36,21H35c-.12-4.59-1.12-13.43-10-13.43S15.14,16.38,15,21H14a4,4,0,0,0-4,4V38.46a4,4,0,0,0,4,4H36a4,4,0,0,0,4-4V25A4,4,0,0,0,36,21ZM25,11.38c4,0,5.89,2.89,6.13,9.59H18.87C19.11,14.27,21,11.38,25,11.38Z"
    />
  </Base>
);
