import React, { useState, useRef } from 'react';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Dropdown, IconActions, Notification } from 'shared/components';
import { hasPermission } from 'shared/helpers';
import ResetLicenseForm from '../ResetLicenseForm';
import './styles.scss';

const ActionMenu = ({
  actions,
  license,
  getLicense,
}) => {
  const { t } = useTranslation();
  const permissionsList = useSelector(state => get(state, 'user.details.permissions'));
  const popupRef = useRef();
  const [isMenuDisplayed, setMenuDisplay] = useState(false);
  const [isResetFormDisplayed, setResetFormDisplay] = useState(false);

  const handleLicenseReset = () => {
    const permission = hasPermission(permissionsList, 'reset_license');
    if (!permission) {
      Notification('error', t('Missing permission'), t('You do not have permission to perform this action.'));
    } else {
      setResetFormDisplay(true);
    }
  };

  const getOptions = () => actions.map(a => (
    <li key={a}>
      <button
        className="li-content"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          handleLicenseReset();
        }}
        type="button"
      >
        {a}
      </button>
    </li>
  ));

  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="ActionMenu" onClick={e => e.stopPropagation()} role="button" tabIndex={0}>
      <button
        className="table-select"
        aria-label={t('Select license')}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleMenu();
        }}
        type="button"
      >
        <IconActions stroke="#2e86de" />
      </button>
      {isMenuDisplayed && (
        <Dropdown ref={popupRef} close={toggleMenu}>
          <ul className="inner-menu">{getOptions()}</ul>
        </Dropdown>
      )}
      {
        isResetFormDisplayed && (
          <ResetLicenseForm
            close={() => setResetFormDisplay()}
            confirmCb={() => {}}
            license={license}
            getLicense={getLicense}
          />
        )
      }
    </div>
  );
};

ActionMenu.propTypes = {
  actions: PropTypes.array.isRequired,
  license: PropTypes.object.isRequired,
  getLicense: PropTypes.func.isRequired,
};

export default ActionMenu;
