import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import { setLicenseListType } from 'redux/licenses/actions';
import {
  Page,
  Selector,
} from 'shared/components';
import {
  LicenseListAll,
  LicenseListFloatingKey,
  LicenseListFloatingUser,
  LicenseListNodeKey,
  LicenseListNodeUser,
} from 'licenses/components';
import { getLicenseUsageTypeOptions } from './helpers';
import './styles.scss';

const ManagerLicenseList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(state => get(state, 'user.details'));
  const licenseListType = useSelector(state => get(state, 'licenses.selectedLicenseListType'));
  const licenseTypesOptions = getLicenseUsageTypeOptions(user);
  const selectedLicenseType = licenseTypesOptions.find(lto => lto.value === licenseListType);

  const handleLicenseTypeChange = val => dispatch(setLicenseListType(val));

  return (
    <Page
      title={t('Licenses')}
      breadcrumb={<NavLink to="/">{t('Home')}</NavLink>}
      headerClassname="noPaddingBottom"
    >
      <div className="ManagerLicenseList">
        <div className="ManagerLicenseList-licensetypes">
          <Selector
            options={licenseTypesOptions.map(o => ({ ...o, label: t(o.label) }))}
            value={selectedLicenseType.value}
            handleChange={handleLicenseTypeChange}
            isSearchable={false}
          />
        </div>
        { selectedLicenseType.value === 'all' && <LicenseListAll licenseType={selectedLicenseType} /> }
        { selectedLicenseType.value === 'floating_key' && <LicenseListFloatingKey licenseType={selectedLicenseType} /> }
        { selectedLicenseType.value === 'floating_user' && <LicenseListFloatingUser licenseType={selectedLicenseType} /> }
        { selectedLicenseType.value === 'node_locked_key' && <LicenseListNodeKey licenseType={selectedLicenseType} /> }
        { selectedLicenseType.value === 'node_locked_user' && <LicenseListNodeUser licenseType={selectedLicenseType} /> }
      </div>
    </Page>
  );
};

export default ManagerLicenseList;
