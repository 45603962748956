import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  DescriptionCard,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  PasswordInput,
} from 'shared/components';
import * as validation from './validation';
import { changeUserPass } from './actions';
import './styles.scss';

const ChangePasswordForm = ({
  close,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordRepeatError, setPasswordRepeatError] = useState('');

  const validateCurrentPassword = async (val) => {
    let errors;

    try {
      errors = await validation.validateCurrentPassword(val);
      setCurrentPasswordError(errors);
    } catch (err) {
      // console.log(err, 'Cannot validate current password field');
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateNewPassword = async (val) => {
    let errors;

    try {
      errors = await validation.validatePassword(val);
      setPasswordError(errors);
    } catch (err) {
      // console.log(err, 'Cannot validate new password field');
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const validateNewPasswordRepeat = async (val) => {
    let errors;

    try {
      errors = await validation.validatePasswordAgain(val, password);
      setPasswordRepeatError(errors);
    } catch (err) {
      // console.log(err, 'Cannot validate new password repeat field');
    }

    if (errors) {
      return false;
    }
    return true;
  };

  const handleCurrentPasswordChange = (val) => {
    setDirty(true);
    setCurrentPassword(val);
    validateCurrentPassword(val);
  };

  const handleNewPasswordChange = (val) => {
    setDirty(true);
    setPassword(val);
    validateNewPassword(val);
  };

  const handleNewPasswordRepeatChange = (val) => {
    setDirty(true);
    setPasswordRepeat(val);
    validateNewPasswordRepeat(val);
  };

  const isFormValid = async () => {
    setLoading(true);
    const isCurrentPassValid = await validateCurrentPassword(currentPassword);
    const isNewPassValid = await validateNewPassword(password);
    const isNewPassRepeatValid = await validateNewPasswordRepeat(passwordRepeat);
    setLoading(false);
    return isCurrentPassValid && isNewPassValid && isNewPassRepeatValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isValid = await isFormValid();

    if (!isValid || loading) {
      return false;
    }

    const userData = {
      old_password: currentPassword,
      new_password1: password,
      new_password2: passwordRepeat,
    };

    setLoading(true);

    try {
      // eslint-disable-next-line no-unused-vars
      const updatedUserData = await changeUserPass(userData);
      setLoading(false);
      Notification('success', t('Profile updated'));
      close();
      return true;
    } catch (err) {
      setLoading(false);
      Notification('error', t('Error occured'), t('Your changes were not saved'));
      return false;
    }
  };

  const handleClose = () => {
    if (!dirty) {
      close();
    }
    return setDirtyFormAlertDisplay(true);
  };

  return (
    <Modal
      title={t('Change password')}
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="lg"
    >
      <div className="ChangePasswordForm">
        <form>
          <DescriptionCard size="sm" theme="info">
            <div className="ChangePassword-directions">
              {t('Password should:')}
              <ul>
                <li>
                  {t('be at least 8 characters long')}
                </li>
                <li>
                  {t('contain at least 1 lowercase letter')}
                </li>
                <li>
                  {t('contain at least 1 uppercase letter')}
                </li>
                <li>
                  {t('contain at least 1 number')}
                </li>
              </ul>
            </div>
          </DescriptionCard>
          <div className="ChangePassword-fields">
            <Label
              inputId="current-password"
              text={t('Current password')}
            />
            <PasswordInput
              id="current-password"
              handleChange={handleCurrentPasswordChange}
              value={currentPassword}
              error={currentPasswordError}
              disabled={loading}
            />
            <Label
              inputId="password"
              text={t('New password')}
            />
            <PasswordInput
              id="password"
              handleChange={handleNewPasswordChange}
              value={password}
              error={passwordError}
              disabled={loading}
            />
            <Label
              inputId="password-again"
              text={t('Password again')}
            />
            <PasswordInput
              id="password-again"
              handleChange={handleNewPasswordRepeatChange}
              value={passwordRepeat}
              error={passwordRepeatError}
              disabled={loading}
            />
          </div>
        </form>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={close}
          />
        )
      }
    </Modal>
  );
};

ChangePasswordForm.propTypes = {
  close: PropTypes.func.isRequired,
};

export default ChangePasswordForm;
