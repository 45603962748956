import React from 'react';
import PropTypes from 'prop-types';
import {
  parseObject,
  isJSON,
} from 'shared/helpers';
import ReactJson from 'react-json-view';

const JsonView = ({ value, name }) => {
  if (!!value && typeof value === 'object') {
    return (
      <ReactJson
        src={value}
        name={name}
        collapsed={false}
        displayDataTypes={false}
      />
    );
  }

  if (!value || !isJSON(value)) {
    return '';
  }

  const result = JSON.parse(value);

  if (typeof result === 'string') {
    return value;
  }

  return (
    <ReactJson
      src={parseObject(value)}
      name={name}
      collapsed={false}
      displayDataTypes={false}
    />
  );
};

JsonView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
};

JsonView.defaultProps = {
  value: '',
  name: 'metadata',
};

export default JsonView;
