import React from 'react';
import Base from './Base';

export default props => (
  <Base {...props}>
    <g id="hq5aKX.tif">
      <path
        d="M16.19,22.17H3.36c0-.13,0-.24,0-.35V9.26c0-.29.08-.38.37-.38H15.82c.29,0,.38.08.38.38q0,6.27,0,12.55ZM7.26,15.53c0,.76,0,1.52,0,2.28,0,.23,0,.32.3.32H12c.21,0,.3-.06.3-.29q0-2.31,0-4.62c0-.23-.09-.29-.3-.29H7.55c-.25,0-.3.1-.3.32C7.26,14,7.26,14.77,7.26,15.53Z"
      />
      <path
        d="M16.17,41.71H3.36c0-.12,0-.23,0-.35,0-4.18,0-8.37,0-12.55,0-.31.08-.4.39-.4q6,0,12.07,0c.27,0,.4,0,.4.36q0,6.31,0,12.62C16.19,41.49,16.18,41.58,16.17,41.71Zm-3.9-6.66c0-.75,0-1.5,0-2.24,0-.24-.06-.35-.32-.35-1.47,0-2.93,0-4.4,0-.23,0-.31.07-.31.31q0,2.3,0,4.59c0,.24.09.31.32.3H12c.24,0,.32-.08.32-.32C12.27,36.59,12.27,35.82,12.27,35.05Z"
      />
      <path d="M21.23,12.92v-4l.36,0h25c.31,0,.39.09.39.4,0,1.09,0,2.18,0,3.28,0,.27,0,.38-.37.38H21.55Z" />
      <path
        d="M21.21,32.45V31.37c0-.88,0-1.75,0-2.63,0-.26.09-.35.33-.32H46.48c.51,0,.51,0,.51.52,0,1.06,0,2.12,0,3.18,0,.26-.07.36-.34.36H21.52Z"
      />
      <path d="M38.79,20.1H21.23v-4l.39,0H38.37c.42,0,.42,0,.42.42V20.1Z" />
      <path d="M21.21,39.63v-.71c0-1,0-2,0-3,0-.28.07-.37.36-.37,1.52,0,3,0,4.56,0H38.35c.44,0,.44,0,.44.46v3.56Z" />
    </g>
  </Base>
);
