import { combineReducers } from 'redux';
import user from '../user/userReducer';
import licenses from '../licenses/licenseReducer';
import orders from '../orders/ordersReducer';

const rootReducer = combineReducers({
  orders,
  licenses,
  user,
});

export default rootReducer;
