import React from 'react';
import get from 'lodash.get';
import { useSelector } from 'react-redux';
import { isUserManager } from 'shared/helpers';
import { NotFound } from 'shared/components';
import OrdersList from './OrdersList';

const Orders = () => {
  const user = useSelector(state => get(state, 'user.details'));
  const isManager = isUserManager(user);

  if (!isManager) {
    return <NotFound />;
  }

  return <OrdersList />;
};

export default Orders;
