import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
} from 'shared/components';
import {
  generatePass,
} from 'shared/helpers';
import * as validation from './validation';
import { addLicenseUser, checkExistingUser } from './actions';
import ExistingUserForm from './ExistingUserForm';

const LicenseUserForm = ({
  closeCb,
  confirmCb,
  license,
  title,
}) => {
  const { t } = useTranslation();
  const [dirty, setDirty] = useState(false);
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState(generatePass());
  const [passwordError, setPasswordError] = useState('');
  const [existingUser, setExistingUser] = useState(null);
  const [showExistingUserForm, setExistingUserFormDisplay] = useState(false);

  const licenseUsers = get(license, 'license_users') || [];

  const validateUserEmail = (val) => {
    const licenseUsersEmailList = licenseUsers.map(u => u.true_email.toLowerCase());
    if (licenseUsersEmailList.includes(val.toLowerCase())) {
      setEmail(val);
      setEmailError(t('User with this email is already added to the list'));
      return false;
    }

    const isInvalid = validation.validateUserEmail(val);

    if (isInvalid) {
      setEmailError(isInvalid);
      return false;
    }

    setEmailError('');
    return true;
  };

  const validatePasword = (val) => {
    const isInvalid = validation.validatePassword(val);

    if (isInvalid) {
      setPasswordError(isInvalid);
      return false;
    }

    setPasswordError('');
    return true;
  };

  const handleEmailChange = (val) => {
    setDirty(true);
    setEmail(val);
    validateUserEmail(val);
  };

  const handlePasswordChange = (val) => {
    setDirty(true);
    setPassword(val);
    validatePasword(val);
  };

  const validateForm = () => {
    const isEmailValid = validateUserEmail(email);
    const isPasswordValid = validatePasword(password);
    return isEmailValid && isPasswordValid;
  };

  const addUser = () => {
    const data = {
      email,
      password,
    };
    const licenseId = get(license, 'id');

    addLicenseUser(licenseId, data)
      .then(() => {
        confirmCb();
        Notification('success', t('Changes saved successfully'), t('License user has been assigned'));
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
      });
  };

  const checkIfUserExisting = () => {
    checkExistingUser(email)
      .then((res) => {
        const count = get(res, 'data.count');
        if (count < 1) {
          return addUser();
        }

        const response = get(res, 'data.results[0]');
        setExistingUser(response);
        return setExistingUserFormDisplay(true);
      })
      .catch(() => {
        setLoading(false);
        Notification('error', t('Your changes were not saved'), t('There was an error while saving your changes'));
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isFormValid = validateForm();

    if (!isFormValid || loading) {
      return false;
    }

    setDirty(false);
    setLoading(true);

    return checkIfUserExisting();
  };

  const handleClose = () => {
    if (!dirty) {
      closeCb();
    } else {
      setDirtyFormAlertDisplay(true);
    }
  };

  return (
    <Modal
      title={title}
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size="sm"
    >
      <div className="LicenseUserForm">
        <div>
          <Label
            text={t('Email')}
            inputId="email"
          />
          <TextInput
            id="email"
            value={email}
            error={emailError}
            handleChange={handleEmailChange}
          />
        </div>
        <div>
          <Label
            text={t('Password')}
            inputId="password"
          />
          <TextInput
            id="password"
            value={password}
            error={passwordError}
            handleChange={handlePasswordChange}
          />
        </div>
      </div>
      {
        isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeCb}
          />
        )
      }
      {
        showExistingUserForm && (
          <ExistingUserForm
            existingUser={existingUser}
            closeCb={() => {
              setExistingUser(null);
              setExistingUserFormDisplay(false);
              setLoading(false);
            }}
            confirmCb={confirmCb}
            licenseId={get(license, 'id')}
          />
        )
      }
    </Modal>
  );
};

LicenseUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default LicenseUserForm;
