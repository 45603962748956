/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { GlobalError } from 'shared/components';
import store from 'redux/store';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import './index.scss';

import './i18n';

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') {
  Sentry.init({ dsn: 'https://55fa48b9797f4940bfb20305fd479df2:e5950e7b2d9649d4965f36a15fc93d90@sentry.kraken.hr/30' });
}

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={GlobalError}>
    <Provider store={store}>
      <Suspense fallback="...">
        <App />
      </Suspense>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
