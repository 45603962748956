import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  List,
  SearchForm,
  StatusCard,
} from 'shared/components';
import {
  displayValue,
  formatDate,
  getLicenseStatus,
  transformSort,
} from 'shared/helpers';
import {
  searchType,
  errorMsg,
} from 'shared/constants';
import { getLicenses } from 'redux/licenses/actions';
import BatchLicenseAssign from '../BatchLicenseAssign';
import ActionMenu from '../ActionMenu';

const LicenseListNodeUser = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const licenses = useSelector(state => get(state, 'licenses.list.results') || []);
  const loading = useSelector(state => get(state, 'licenses.loading'));
  const pages = useSelector(state => Math.ceil(get(state, 'licenses.list.count') / 20));

  const [activeQuery, setActiveQuery] = useState('');
  const [page, setPage] = useState(0);
  const [searchBy, setSearchBy] = useState(searchType.licenseUserEmail);
  const [currentSort, setCurrentSort] = useState([{ desc: true, id: 'created_at' }]);
  const [selectedLicenses, setSelectedLicenses] = useState([]);
  const [isBatchLicenseAssignDisplayed, setBatchLicenseDisplay] = useState(false);
  const [shouldGetLicenses, setShouldGetLicenses] = useState(0);

  useEffect(() => {
    dispatch(getLicenses(page, activeQuery, searchBy, transformSort(currentSort), '&is_any_floating=0&license_key__isnull=1'));
  }, [page, activeQuery, currentSort, shouldGetLicenses]);

  const redirectToLicensePage = (rowData) => {
    const licenseId = get(rowData, 'original.id');
    history.push(`/licenses/${licenseId}`);
  };

  const handlePageChange = newPage => setPage(newPage);
  const handleSortChange = newSorted => setCurrentSort(newSorted);
  const handleSearchTypeChange = newSearchType => setSearchBy(newSearchType);
  const handleClear = () => setActiveQuery('');

  const handleSearchSubmit = (val) => {
    setActiveQuery(val);
    setPage(0);
  };

  return (
    <div className="LicenseListNodeUser">
      <div className="list-header">
        <SearchForm
          options={[
            {
              label: t('Search by license user email'),
              value: searchType.licenseUserEmail,
            },
            {
              label: t('Search by license key'),
              value: searchType.licenseKey,
            },
            {
              label: t('Search by order id'),
              value: searchType.orderID,
            },
            {
              label: t('Search by product name'),
              value: searchType.productName,
            },
            {
              label: t('Search by device hostname'),
              value: searchType.hostname,
            },
          ]}
          selectedValue={searchBy}
          handleSearchTypeChange={handleSearchTypeChange}
          handleSearchSubmit={handleSearchSubmit}
          activeQuery={activeQuery}
          handleClear={handleClear}
          disabled={loading}
        />
      </div>
      <List
        data={licenses || []}
        columns={[
          {
            id: 'select',
            Header: t('Select'),
            className: 'select-container',
            Cell: cellData => (
              <button
                className="table-select"
                aria-label={t('Select license')}
                onClick={(e) => {
                  e.stopPropagation();
                  let newState;
                  const isSelected = selectedLicenses.includes(cellData.original.id);
                  if (isSelected) {
                    newState = selectedLicenses.filter(l => l !== cellData.original.id);
                  } else {
                    newState = selectedLicenses.concat(cellData.original.id);
                  }
                  setSelectedLicenses(newState);
                }}
                type="button"
              >
                <Checkbox
                  inputId={`${cellData.original.id}-select`}
                  handleChange={(val, e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                  checked={selectedLicenses.includes(cellData.original.id)}
                />
              </button>
            ),
            width: 70,
            show: false,
            sortable: false,
          },
          {
            accessor: 'created_at',
            Header: t('Created on'),
            Cell: cellInfo => formatDate(cellInfo.value),
            width: 140,
          },
          {
            accessor: 'order_store_id',
            Header: t('Order ID'),
            Cell: cellInfo => displayValue(cellInfo.value),
          },
          {
            id: 'license_key',
            Header: t('License users'),
            width: 250,
            sortable: false,
            Cell: (rowData) => {
              const isBundle = get(rowData, 'original.bundle_license');
              if (isBundle) return '-';
              const licenseUsers = get(rowData, 'original.license_users') || [];
              const usersNum = licenseUsers.length;
              if (usersNum > 0) {
                const firstUser = get(licenseUsers, '[0].true_email');
                const isSingleUser = usersNum === 1;
                if (isSingleUser) {
                  return `${firstUser}`;
                }
                return `${firstUser} (+${usersNum - 1})`;
              }
              return t(errorMsg.notAssigned);
            },
          },
          {
            accessor: 'product.product_name',
            Header: t('Product'),
          },
          {
            accessor: 'license_type',
            Header: t('License Type'),
            Cell: (rowData) => {
              const isBundle = get(rowData, 'original.is_bundle');
              if (isBundle) return '-';
              return displayValue(t(rowData.value));
            },
            width: 110,
          },
          {
            accessor: 'time_activated',
            Header: t('Activated on'),
            Cell: (rowData) => {
              const isBundle = get(rowData, 'original.is_bundle');
              if (isBundle) return '-';
              return formatDate(rowData.value);
            },
            width: 140,
          },
          {
            accessor: 'times_activated',
            className: 'text-center',
            headerClassName: 'text-center',
            Header: t('Times activated'),
            Cell: (rowData) => {
              const isBundle = get(rowData, 'original.is_bundle');
              if (isBundle) return '-';
              return displayValue(rowData.value, 0);
            },
            width: 130,
          },
          {
            accessor: 'maintenance_period',
            Header: t('Maintenance End Date'),
            Cell: (rowData) => {
              const isBundle = get(rowData, 'original.is_bundle');
              if (isBundle) return '-';
              return displayValue(rowData.value);
            },
          },
          {
            accessor: 'enabled',
            className: 'text-center',
            Header: t('Status'),
            headerClassName: 'text-center',
            Cell: (rowData) => {
              const isBundle = get(rowData, 'original.is_bundle');
              if (isBundle) return '-';
              const status = getLicenseStatus(
                get(rowData, 'original.enabled'),
                get(rowData, 'original.active'),
              );
              return (
                <StatusCard
                  status={get(status, 'positive') ? 'success' : 'error'}
                  text={t(get(status, 'message'))}
                />
              );
            },
            width: 90,
          },
          {
            id: 'actions',
            className: 'select-container action-menu',
            Header: t('Actions'),
            headerClassName: 'text-center',
            Cell: rowData => (
              <ActionMenu actions={[t('Reset')]} license={rowData.original} getLicense={() => setShouldGetLicenses(shouldGetLicenses + 1)} />
            ),
            width: 70,
          },
        ]}
        clickable
        hasDropdown
        page={page}
        pages={pages}
        loading={loading}
        manual
        minRows={get(licenses, 'length') || 10}
        showPagination={pages > 1}
        onPageChange={handlePageChange}
        onSortedChange={handleSortChange}
        defaultSorted={currentSort}
        handleClick={rowData => redirectToLicensePage(rowData)}
      />
      {
        isBatchLicenseAssignDisplayed && (
          <BatchLicenseAssign
            closeCb={() => setBatchLicenseDisplay(false)}
            confirmCb={() => {
              setBatchLicenseDisplay(false);
              setShouldGetLicenses(shouldGetLicenses + 1);
            }}
            licenses={licenses}
          />
        )
      }
    </div>
  );
};

export default LicenseListNodeUser;
