import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Label,
  IconActions,
  IconBorrow,
  IconReturn,
  IconReset,
  IconRevoke,
  Modal,
} from 'shared/components';
import './styles.scss';

const DeviceActionMenu = ({
  device,
  license,
  handleDeviceResetForm,
  handleManageBorrow,
  handleReturnBorrow,
  handleDeviceRevoke,
  isResetDisabled,
  isBorrowDisabled,
  isRevokeDisabled,
}) => {
  const { t } = useTranslation();
  const [isMenuDisplayed, setMenuDisplay] = useState(false);
  const toggleMenu = () => setMenuDisplay(!isMenuDisplayed);

  const isBorrowed = get(device, 'borrowed_until');
  const isFloating = get(license, 'is_floating_cloud') || get(license, 'is_floating');
  const isFloatingInUse = get(device, 'floating_in_use');

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div className="DeviceActionMenuBtn" onClick={e => e.stopPropagation()} role="button" tabIndex={0}>
      <button
        className="table-select"
        aria-label={t('Select license')}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          toggleMenu();
        }}
        type="button"
      >
        <IconActions stroke="#2e86de" />
      </button>
      {isMenuDisplayed && (
        <Modal confirmCb={null} hideFooter closeCb={toggleMenu} size="sm">
          <div className="DeviceActionMenu">
            <div className="header">
              <Label text={t('Hardware ID')} />
              <div className="text">{get(device, 'hardware_id')}</div>
            </div>
            <ul className="inner-menu">
              <li>
                <Button
                  className="reset-button"
                  onClick={() => handleDeviceResetForm(device)}
                  type="button"
                  disabled={isResetDisabled}
                >
                  <>
                    <div className="icon-wrapper">
                      <IconReset
                        fill="none"
                        stroke="#000"
                        strokeWidth="2"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      />
                    </div>
                    <div>{t('Reset')}</div>
                  </>
                </Button>
              </li>
              <li>
                <Button
                  className="table-button"
                  onClick={() => handleDeviceRevoke(device)}
                  type="button"
                  disabled={!isFloatingInUse || isRevokeDisabled}
                >
                  <>
                    <div className="icon-wrapper">
                      <IconRevoke width="24" height="24" viewBox="0 0 100 100" />
                    </div>
                    <div>{t('Revoke')}</div>
                  </>
                </Button>
              </li>
              {isFloating && (
              <li>
                <Button
                  className="table-button"
                  onClick={() => handleManageBorrow(device)}
                  type="button"
                  disabled={isBorrowDisabled}
                >
                  <>
                    <div className="icon-wrapper">
                      <IconBorrow
                        fill="#000"
                        width="22"
                        height="22"
                      />
                    </div>
                    <div>{isBorrowed ? t('Manage borrowed license') : t('Borrow')}</div>
                  </>
                </Button>
              </li>
              )}
              {isBorrowed && (
                <li>
                  <Button
                    className="table-button"
                    onClick={() => handleReturnBorrow(device)}
                    type="button"
                    disabled={isBorrowDisabled}
                  >
                    <>
                      <div className="icon-wrapper">
                        <IconReturn
                          fill="#000"
                          width="24"
                          height="24"
                        />
                      </div>
                      <div>{t('Return borrowed license')}</div>
                    </>
                  </Button>
                </li>
              )}
            </ul>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeviceActionMenu.propTypes = {
  device: PropTypes.object.isRequired,
  license: PropTypes.object.isRequired,
  handleDeviceResetForm: PropTypes.func.isRequired,
  handleManageBorrow: PropTypes.func.isRequired,
  handleReturnBorrow: PropTypes.func.isRequired,
  handleDeviceRevoke: PropTypes.func.isRequired,
  isBorrowDisabled: PropTypes.bool.isRequired,
  isResetDisabled: PropTypes.bool.isRequired,
  isRevokeDisabled: PropTypes.bool.isRequired,
};

export default DeviceActionMenu;
