import api from 'shared/api';

export const generateLicense = (companyID, productShortCode, licenseQuantity) => {
  const url = `/api/v1/orders/generate_license/?product=${productShortCode}&quantity=${licenseQuantity}&company=${companyID}`;
  return api.get(url);
};

export const getOrder = (companyID, orderID) => {
  const url = `/api/v1/orders/${orderID}/?company=${companyID}`;
  return api.get(url);
};

export const createOrder = (companyID, data) => api.post(`/api/v1/orders/create_order/?company=${companyID}`, data);

export const getProductsInBundle = bundleID => api.get(`/api/v1/products/${bundleID}/bundle/`);
