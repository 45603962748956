import { useState, useEffect } from 'react';
import get from 'lodash.get';
import axios from 'axios';
import api from '../api';

const useAPI = ({
  method = 'get',
  url,
  params = undefined,
  onSuccess = () => {},
  onError = () => {},
  dependenciesArray = [],
}) => {
  const [isLoading, setLoading] = useState(true);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // track whether component is mounted
    let isMounted = true;
    // use axios cancel token to cancel request if component is unmounted
    const axiosCancelSource = axios.CancelToken.source();

    // url is required
    if (!url) {
      setLoading(false);
      // eslint-disable-next-line no-console
      return console.error('Url is required param when using useAPI hook');
    }

    api[method](url, params, { cancelToken: axiosCancelSource.token })
      .then((res) => {
        if (isMounted) {
          const data = get(res, 'data');
          setValue(data);
          setLoading(false);
          onSuccess(data);
        }
      })
      .catch((err) => {
        if (isMounted) {
          setError(err);
          setLoading(false);
          onError(err);
        }
      });

    return () => {
      // clean up
      isMounted = false;
      // cancel request
      axiosCancelSource.cancel('Axios request canceled.');
    };
  }, dependenciesArray);

  return { isLoading, value, error };
};

export default useAPI;
